import { FC } from "react";
import { dataToShowI } from "../interfaces";
import { Col, Row, Typography } from "antd";
import moment from "moment";

interface Styles {
    dataRow: React.CSSProperties;
    blockContainer: React.CSSProperties;
    strongText: React.CSSProperties;
    sectionTitle: React.CSSProperties;
}

const styles: Styles = {
    dataRow: {
        width: '100%'
    },
    blockContainer: {
        backgroundColor: '#FFF1F0',
        width: '100%',
        padding: '16px',
        border: '1px solid #FFA39E',
        marginBottom: '12px'
    },
    strongText: {
        color: '#F5222D',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    sectionTitle: {
        color: '#000000',
        fontSize: '14px'
    },
}

interface props {
    fullUser: dataToShowI;
}

const BlockAlert: FC<props> = ({ fullUser }) => {
    return (
        <Row style={styles.dataRow}>
            <Col xs={24} md={24} lg={24}>
                <section style={styles.blockContainer}>
                    <section>
                        <Typography style={styles.sectionTitle}>
                            <span style={styles.strongText}>{fullUser?.blacklist?.type}</span> -  {fullUser?.blacklist?.block_reason} ({moment(fullUser?.blacklist?.ban_date).format('ll')})
                        </Typography>
                    </section>
                </section>
            </Col>
        </Row>
    )
}

export default BlockAlert
