import React, { useEffect, useState } from "react";
import { Table } from "../../components/Common/Table";
import { quotesColumn } from "../../services/records";
import { usePost } from "../../hooks/usePost";
import { quotesPerCarrier } from "../../services/admin_s";
import { allCarriers } from "../../utils/ArrayCarrier";
import { ServicesId_MX } from "../../utils/GlobalServices";
import { Button, Form, Input, Modal, Select, Space } from "antd";

const { Option } = Select;

const providerMap: Record<string, string[]> = {
	"D-C0": ["estafeta", "fedex", "dhl"],
	"A-P01": ["envia"],
	"A-P04": ["shipandgo"],
	"A-P09": ["globalpaq"],
	"A-P03": ["pack2go"],
	"A-P10": ["t1"],
	"A-P08": ["pakke"],
	"A-P02": ["shippo"],
};

const QuotesPerCarrier: React.FC = () => {
	const [form] = Form.useForm();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [services, setServices] = useState<any[]>([]);
	const [carrier, setCarrier] = useState<any>();
	const [serviceSelectd, setServiceSelected] = useState<any>('');
	const [servicesLabel, setServicesLabel] = useState<any[]>([]);
	const [providers, setProviders] = useState<any[]>([]);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const servicesFiltered = Object.values(ServicesId_MX).filter((service) =>
			service.includes(carrier)
		);
		const serivicesLabel = Object.keys(ServicesId_MX).filter((service) =>
			service.includes(carrier)
		);
		setServicesLabel(serivicesLabel);
		setServices(servicesFiltered);
	}, [carrier]);

	useEffect(() => {
		const selectedProviders =
			Object.entries(providerMap).find(([key]) => serviceSelectd.includes(key))?.[1] || [];
		setProviders(selectedProviders);
	}, [serviceSelectd]);

	const [data, setData] = useState<any[]>([]);
	const { fetchData } = usePost(quotesPerCarrier);

	const showModal = () => setIsModalOpen(true);
	const handleOk = () => setIsModalOpen(false);
	const handleCancel = () => setIsModalOpen(false);

	const onFinish = async (values: any) => {
		setIsLoaded(true);
		const { data } = await fetchData({
			providerSelected: values.provider,
			carrierSelected: values.carrier,
			zoneSelected: values.zone,
			range1: Number(values.weight),
			range2: Number(values.weight2),
			service: values.service,
		});

		const formattedData = data.map((d: any) => ({
			weight: d.weight,
			cost: d.cost,
		}));

		setData(formattedData);
		setIsLoaded(false);
	};

	const onReset = () => form.resetFields();

	return (
		<>
			<Table
				// @ts-ignore
				data={data}
				isLoading={isLoaded}
				columns={quotesColumn()}
				title="Cotizaciones por Paqueteria"
				component={
					<>
						<Button onClick={showModal}>Filtar</Button>
						<Modal title="Filtros" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
							<Form
								layout="vertical"
								form={form}
								name="basic"
								onFinish={onFinish}
								initialValues={{ layout: "vertical" }}
								style={{ maxWidth: 400 }}
							>
								<Form.Item name="weight" label="Peso Inicial" rules={[{ required: true }]}>
									<Input type="number" placeholder="Escoge el peso inicial" />
								</Form.Item>
								<Form.Item name="weight2" label="Peso Final" rules={[{ required: true }]}>
									<Input type="number" placeholder="Escoge el peso final" />
								</Form.Item>
								<Form.Item name="carrier" label="Paqueterias" rules={[{ required: true }]}>
									<Select
										placeholder="Selecciona la paqueteria"
										onSelect={(a: any) => {
											setCarrier(a);
										}}
										allowClear
									>
										{allCarriers.map((carrier) => (
											<Option key={carrier} value={carrier}>
												{carrier}
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item name="service" label="Servicios" rules={[{ required: true }]}>
									<Select
										placeholder="Selecciona el servicio"
										onSelect={(s: any) => setServiceSelected(s)}
										allowClear
									>
										{services.map((service, index) => (
											<Option key={servicesLabel[index]} value={service}>
												{servicesLabel[index]}
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item name="zone" label="Zonas" rules={[{ required: true }]}>
									<Select placeholder="Selecciona la zona" allowClear>
										{["zone 1", "zone 2", "zone 3", "zone 4", "zone 8"].map((zone) => (
											<Option key={zone} value={zone}>
												{zone}
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item name="provider" label="Proveedor" rules={[{ required: true }]}>
									<Select placeholder="Selecciona el proveedor" allowClear>
										{providers.map((provider) => (
											<Option key={provider} value={provider}>
												{provider}
											</Option>
										))}
									</Select>
								</Form.Item>
								<Form.Item
									noStyle
									shouldUpdate={(prevValues, currentValues) =>
										prevValues.gender !== currentValues.gender
									}
								>
									{({ getFieldValue }) =>
										getFieldValue("gender") === "other" ? (
											<Form.Item
												name="customizeGender"
												label="Customize Gender"
												rules={[{ required: true }]}
											>
												<Input />
											</Form.Item>
										) : null
									}
								</Form.Item>
								<Form.Item style={{ marginTop: 10 }}>
									<Space>
										<Button type="primary" htmlType="submit">
											Cotizar
										</Button>
										<Button htmlType="button" onClick={onReset}>
											Limpiar formulario
										</Button>
									</Space>
								</Form.Item>
							</Form>
						</Modal>
					</>
				}
			/>
		</>
	);
};

export default QuotesPerCarrier;
