import { Col, Row } from 'antd';
import { Fragment, useState, useEffect } from 'react';
import { DashboardAnalyticsRes } from '../../../../interfaces/admin.interface';
import CardContainer from '../../../../components/Containers/CardContainer';
import LoadingAnimation from '../../../../components/LoadingAnimation';
import { Tooltip } from "antd";

type Props = {
  filteredData: DashboardAnalyticsRes
  isLoading: boolean
}

export const NewUsersShipments = ({ filteredData, isLoading }: Props) => {
  const [users, setUsers] = useState<{ profileEmail: string, created_at: string, totalShipments: string }[]>([]);

  useEffect(() => {
    if (filteredData?.NewUsersShipments) {
      setUsers(filteredData.NewUsersShipments);
    }
  }, [filteredData]);

  return (
    <CardContainer cardStyle={{ marginTop: '20px' }} title='Nuevos usuarios con envios' titleStyle={{ placeItems: 'center' }}
    >
      <hr />
      <div>
        <Row>
          <Col span={12} style={{ fontWeight: 'bold', textAlign: 'center' }}>
            <p>Usuario</p>
          </Col>
          <Col span={8} style={{ fontWeight: 'bold', textAlign: 'center' }}>
            <p>Creado</p>
          </Col>
          <Col span={4} style={{ fontWeight: 'bold', textAlign: 'center' }}>
            <p>Envios</p>
          </Col>
        </Row>
        <Row
          style={{ maxHeight: "40vh", overflowY: 'auto' }}
        >
          {isLoading ? (
            <LoadingAnimation animationType='small' />
          ) : users.length === 0 ? (
            <p
              style={{
                fontWeight: 'bold',
                margin: '50px auto',
                color: '#0008',
              }}
            >
              No hay datos para mostrar.
            </p>
          ) : (
            users.map((item, index) => (
              <Fragment key={index}>
                <Col span={13} style={{ margin: "5px auto", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                  <Tooltip title={item.profileEmail}>{item.profileEmail}</Tooltip>
                </Col>
                <Col span={7} style={{ margin: "5px auto", textAlign: "center", whiteSpace: "nowrap", color: "#2D1B6E", fontWeight: "bold" }}>
                  <Tooltip title={item.created_at}>{item.created_at}</Tooltip>
                </Col>
                <Col span={4} style={{ margin: "5px auto", textAlign: "center", whiteSpace: "nowrap", fontWeight: "bold" }}>
                  <Tooltip title={item.totalShipments}>{item.totalShipments}</Tooltip>
                </Col>
              </Fragment>
            ))
          )}
        </Row>
      </div>
    </CardContainer>
  )
}
