import { Avatar, Badge } from "antd"
import { assingDashboardLogos, switchCountry } from "../utils"
import { useMediaQuery } from 'react-responsive'
import { FC } from "react";

interface props {
    carrier: string;
    country: string;
}

const CarrierAvatar: FC<props> = ({ carrier, country }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' })

    return (
        <Badge
            count={
                <img
                    alt={carrier}
                    src={switchCountry(country)}
                    width={isMobile ? 20 : 24} height={isMobile ? 20 : 24} />
            }
            offset={[-8, isMobile ? 35 : 46]}
        >
            <Avatar
                shape="square"
                size={isMobile ? 42 : 52}
                src={
                    <img src={assingDashboardLogos(carrier)} alt="avatar" />
                }
            />
        </Badge>
    )
}

export default CarrierAvatar