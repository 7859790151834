import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { t } from "i18next";
import { TableV3 } from "../../../components/Common/TableV3";
import { ApiContext } from "../../../context/apiContext";
import { shipmentsColumnV3, shipmentsDownloads } from "./ShipmentsV2";
import { AdminDataContext } from "../../../context/adminContext";
import { filterByColumnsContructor, GetAllFilteredQuery } from "../../../interfaces/table.interface";
interface Props {
	user: string;
}

const filterByColumnsFactory = ({
	country,
	user,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
	country,
	user, 
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
		{ id: "created_at", find: false },
		{ id: "tracking", find: false },
		{ id: "origin", find: false },
		{ id: "destination", find: false },
		{ id: "carrier", find: false },
		{ id: "price", find: false},
		{
			id: "shipment_status",
			find: true,
			filters: {
				sort: false,
				filter: false,
				filter_values: [
					"Activa",
					"Entregado",
					"Cancelada y usada",
					"Saldo abonado",
					"En transito",
					"Excepcion",
					"Cancelado",
					"Solicitada",
					"Cancelada y usada",
				],
			},
		},
	],
})

const ShipmentsByUser: React.FC<Props> = ({ user }) => {
	const { userToken, backendURL } = useContext(ApiContext);
	const { country } = useContext(AdminDataContext);
	const [lastUser, setLastUser] = useState<string>("");
	const [currentPage, setCurrentPage] = useState(1);
	const [rowsPerPages, setRowPerPages] = useState(20);
	const [total, setTotal] = useState(0);
	const [refetching, setIsRefetching] = useState(false);
	const [searchText, setSearchText] = useState<string>("");
	const [dataColumns, setDataColumns] = useState<any>(null);
	const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, user, currentPage, rowsPerPages, searchText }));
	const [initRender, setInitRender] = useState(false)

	const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
		const { data } = await axios.post(
			`${backendURL}shipments/labelsV3ToAdmin`, 
			filterByColumns, 
			{ headers: { Authorization: `Bearer ${userToken}` }}
		);

		setTotal(data?.total)

		if (saveState) {
			setDataColumns(data?.data);
		} else {
			return data?.data ?? [];
		}
	};

	const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

	useEffect(() => {
		if (!initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
				setInitRender(true)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [filterByColumns]) 

	useEffect(() => {
		if (initRender){
			if (lastUser === user) return;
			(async () => {
				setLastUser(user)
				setCurrentPage(1)
				setRowPerPages(20)
				const customFilters = filterByColumnsFactory({ country, user, currentPage: 1, rowsPerPages: 20 })
				setFilterByColumns(customFilters)
			})();
		}
	}, [user]); //eslint-disable-line

	return (
		<TableV3
			title={t("Shipments.Table.Title")}
			tableType="shipments"
			isShipments
			data={dataColumns ?? []}
			total={total}
			isLoading={refetching}
			onChangePage={(page) => setCurrentPage(page)}
			onChangeRowsPerPage={(row) => setRowPerPages(row)}
			schemaDownload={shipmentsDownloads}
			columns={shipmentsColumnV3(dataColumns ?? []) as any}
			noFormattedDate={true}
			stateValues={{
				country,
				currentPage,
				rowsPerPages,
				searchText,
				filterByColumns,
			}}
			filters={{
				filterBySearchText(text: string) {
					setSearchText(text);
				},
				filterByColumns(filter: GetAllFilteredQuery) {
					setFilterByColumns(filter);
				},
				filterDownloads: async (filter: GetAllFilteredQuery) => {
					return await handleFecth(filter, false);
				},
				handleStateFilterByColumns: (props: filterByColumnsContructor) => {
					return handleStateFilterByColumns(props)
				},
				filterByColumnsFactory: (props: filterByColumnsContructor)=> {
					return filterByColumnsFactory(props)
				}
			}}
		/>
	);
};

export default ShipmentsByUser;
