// import { ColumnsType } from 'antd/lib/table'
import axios from 'axios'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiContext } from '../../context/apiContext'
import { TableV3 } from '../../components/Common/TableV3'
import TrackingUser from './TrackingUser'
import { Avatar, Col, Divider, Row, Tag, Tooltip } from 'antd'
import { ErrorOutline, PhoneOutlined } from '@material-ui/icons'
import ModalContainer from '../../components/Containers/ModalContainer'
import { SwitchLogos } from './Tracing'
import { t } from 'i18next'
import { AdminDataContext } from '../../context/adminContext'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../interfaces/table.interface'

const backendUrl = process.env.REACT_APP_NOT_BACKEND_URL
interface ModalData {
    open: boolean
    component: string
    data?: any
}

const filterByColumnsFactory = ({
	country,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
    country,
    range: { from: currentPage, to: rowsPerPages },
    searched: searchText,
    columns: [
        { id: 'created_at', find: false },
        { id: 'name', find: false },
        { id: 'email', find: false },
        { id: 'company', find: false },
        { id: 'reg_method', find: false },
        { id: 'app_country', find: false },
        { id: 'user_activity', find: false },
    ]
})

const TracingV2 = () => {
    const { t } = useTranslation()
    const { userToken } = useContext(ApiContext)
    const { country } = useContext(AdminDataContext)
    const [currentPage, setCurrentPage] = useState(1)
    const [rowsPerPages, setRowPerPages] = useState(20)
    const [total, setTotal] = useState(0)
    const [refetching, setIsRefetching] = useState(false)
    const [searchText, setSearchText] = useState<string>('')
    const [dataColumns, setDataColumns] = useState<any>(null)
    const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
    const [initRender, setInitRender] = useState(false)

    const [isModalOpen, setIsModalOpen] = useState<ModalData>({ open: false, component: "" })

    const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
        const { data } = await axios.post(
            `${backendUrl}marketing/getDataV2`, 
            filterByColumns, 
            { headers: { Authorization: `Bearer ${userToken}`}}
        )

        setTotal(data?.total)
        if (saveState){
        setDataColumns(data?.data)
        } else {
        return data?.data ?? []
        }
    }

	const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

    const viewMoreInfo = (data: any) => {
        setIsModalOpen({ open: true, component: "moreInfo", data })
    }


    useEffect(() => {
        if (!initRender){
          (async () => {
            setIsRefetching(true)
            await handleFecth(filterByColumns)
            setIsRefetching(false)
            setInitRender(true)
          })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 
      
    useEffect(() => {
        if (initRender){
          (async () => {
            setIsRefetching(true)
            await handleFecth(filterByColumns)
            setIsRefetching(false)
          })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByColumns]) 
    
	// useEffect(() => {
	// 	if (initRender){
	// 	  	(async () => {
	// 			setCurrentPage(1)
	// 			const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
	// 			setFilterByColumns(customFilters)
	// 	  	})()
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [country])

    return (
        <>
            <Row gutter={[10, 10]} style={{ maxHeight: "100%", overflowY: "scroll", overflowX: "scroll" }} >
                <div >
                    <TableV3
                        title={t('Tracking.Table.Title')}
                        tableType="tracking"
                        data={dataColumns ?? []}
                        total={total}
                        isLoading={refetching}
                        onChangePage={(page) => setCurrentPage(page)}
                        onChangeRowsPerPage={(row) => setRowPerPages(row)}
                        config={{ disableFilters: false }}
                        schemaDownload={downloads}
                        columns={columns(viewMoreInfo)}
                        stateValues={{
                            country,
                            currentPage,
                            rowsPerPages,
                            searchText,
                            filterByColumns,
                        }}
                        filters={{
                            filterBySearchText(text: string) {
                                setSearchText(text)
                            },
                            filterByColumns(filter: GetAllFilteredQuery) {
                                setFilterByColumns(filter)
                            },
                            filterDownloads: async (filter: GetAllFilteredQuery) => {
                                return await handleFecth(filter, false)
                            },
                            handleStateFilterByColumns: (props: filterByColumnsContructor) => {
                                return handleStateFilterByColumns(props)
                            },
                            filterByColumnsFactory: (props: filterByColumnsContructor)=> {
                                return filterByColumnsFactory(props)
                            }
                        }}
                    />
                </div>
                <ModalContainer
                    title="Info del usuario"
                    visible={isModalOpen?.open}
                    onCancel={() => setIsModalOpen({ ...isModalOpen, open: !isModalOpen.open }) }
                    footer={<></>}
                >
                    <Row gutter={[10, 10]} style={{ textAlign: "center" }} >
                        {isModalOpen?.data !== undefined ? (
                            <>
                                <Col
                                    span={24}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        textAlign: "center",
                                    }}
                                >
                                    <Avatar style={{ background: "#099ad3" }} size={64}>
                                        {(isModalOpen?.data?.name[0]).toUpperCase()}
                                    </Avatar>
                                    <div style={{ textAlign: "center", margin: "0 15px" }}>
                                        <p>{isModalOpen?.data?.name || ""}</p>
                                        <Tag color="blue">{isModalOpen?.data?.user}</Tag>
                                    </div>
                                </Col>
                                <Divider></Divider>
                                <Col span={8} style={{ margin: "10px 0" }}>
                                    <span style={{ margin: "5px 0" }}>Télefono</span> <br />
                                    <Tag color="blue">
                                    <PhoneOutlined style={{ fontSize: "15px" }} />{" "}
                                    {isModalOpen?.data?.userData[0]?.app_country === "CO"
                                        ? "+57 "
                                        : "+52 "}{" "}
                                    {isModalOpen?.data?.phone}
                                    </Tag>
                                </Col>
                                <Col span={8} style={{ margin: "10px 0" }}>
                                    <span style={{ margin: "5px 0" }}>País</span> <br />
                                    {SwitchLogos(isModalOpen?.data?.userData[0]?.app_country)}
                                </Col>
                                <Col span={8} style={{ margin: "10px 0" }}>
                                    <span style={{ margin: "5px 0" }}>Método de registro</span>{" "}
                                    <br />
                                    {SwitchLogos(isModalOpen?.data?.reg_method)}
                                </Col>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <span style={{ margin: "5px 0" }}>Correo</span> <br />
                                    <Tag color="blue">{isModalOpen?.data?.email}</Tag>
                                </Col>
                            </>
                        ) : (
                            "No hay data..."
                        )}
                    </Row>
                </ModalContainer>
            </Row>
        </>
    )
}

const columns =  (
    viewMoreInfo: (data: any) => void 
) => {

    const RenderColumn = (field: any) => {
        if (field) return field
        return <small style={{ fontWeight: "600" }}>NO DISPONIBLE</small>
    }

    return [
        {
            title: t('Tracking.Table.Column.Tracking'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => TrackingUser(rowData),
        },
        {
            title: t('Tracking.Table.Column.Date'),
            dataIndex: '',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => new Date(a?.created_at).getTime() - new Date(b?.created_at).getTime(),
            render: (rowData: any) => rowData?.created_at ? moment(rowData.created_at).format("LL") : 'N/A',
        },
        {
            title: t('Tracking.Table.Column.Name'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => RenderColumn(rowData.name),
        },
        {
            title: t('Tracking.Table.Column.Email'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => RenderColumn(rowData.email),
        },
        {
            title: t('Tracking.Table.Column.Company'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => RenderColumn(rowData.company),
        },
        {
            title: t('Tracking.Table.Column.Actions'),
            align: 'center',
            render: (rowData: any) => {
                return (
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <Tooltip title="Ver más información">
                        <ErrorOutline
                            style={{ cursor: "pointer", rotate: "180deg", color: "#009689" }}
                            onClick={() => viewMoreInfo(rowData)}
                        />
                    </Tooltip>
                    </div>
                );
            },
        },
    ]
}


export const downloads = (data: any[])=> {
    return data.map((item) => ({
        date: new Date(item.created_at).toLocaleString(),
        name: item.name,
        email: item.email,
        company: item.company,
    })) 
}

export default TracingV2