import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { TableV3 } from "../../components/Common/TableV3";
import { sellerTrackingColumn } from "../DashboardSections/Data/Partners/schemaColumns";
import { sellerTrackingDownloads } from "../DashboardSections/Data/Partners/schemaDownloads";
import { ApiContext } from "../../context/apiContext";
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../interfaces/table.interface';
import { AdminDataContext } from '../../context/adminContext';

const backendUrl = process.env.REACT_APP_NOT_BACKEND_URL

const filterByColumnsFactory = ({
	country,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
    country,
    range: { from: currentPage, to: rowsPerPages },
    searched: searchText,
    columns: [
		{ id: 'sellerId', find: false },
		{ id: 'sellerEmail', find: false },
		{ id: 'customerId', find: false },
		{ id: 'customerEmail', find: false },
	]
})

const SellerTracking = () => {
	const { userToken } = useContext(ApiContext)
	const { country } = useContext(AdminDataContext)
	const [currentPage, setCurrentPage] = useState(1)
	const [rowsPerPages, setRowPerPages] = useState(20)
	const [total, setTotal] = useState(0)
	const [refetching, setIsRefetching] = useState(false)
	const [searchText, setSearchText] = useState<string>('')
	const [dataColumns, setDataColumns] = useState<any>(null)
	const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
    const [initRender, setInitRender] = useState(false)

	const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
		const { data } = await axios.post(
			`${backendUrl}admin/getSellerCustomerContact`, 
			filterByColumns, 
			{ headers: { Authorization: `Bearer ${userToken}` } }
		)

		setTotal(data?.total)
		if (saveState){
			setDataColumns(data?.data)
		} else {
			return data?.data ?? []
		}
	}

	const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

    useEffect(() => {
        if (!initRender){
          (async () => {
            setIsRefetching(true)
            await handleFecth(filterByColumns)
            setIsRefetching(false)
            setInitRender(true)
          })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 
      
    useEffect(() => {
        if (initRender){
          (async () => {
            setIsRefetching(true)
            await handleFecth(filterByColumns)
            setIsRefetching(false)
          })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByColumns]) 
    
	// useEffect(() => {
	// 	if (initRender){
	// 	  	(async () => {
	// 			setCurrentPage(1)
	// 			const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
	// 			setFilterByColumns(customFilters)
	// 	  	})()
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [country])

	return (
		<div>
			<TableV3
				// title={t('Profiles.Table.Title')}
				tableType="sellerTracking"
				data={dataColumns ?? []}
				total={total}
				isLoading={refetching}
				onChangePage={(page) => setCurrentPage(page)}
				onChangeRowsPerPage={(row) => setRowPerPages(row)}
				config={{
					disableFilters: true
				}}
				schemaDownload={sellerTrackingDownloads}
				columns={sellerTrackingColumn()}
				stateValues={{
					country,
					currentPage,
					rowsPerPages,
					searchText,
					filterByColumns,
				}}
				filters={{
					filterBySearchText(text: string) {
						setSearchText(text)
					},
					filterByColumns(filter: GetAllFilteredQuery) {
						setFilterByColumns(filter)
					},
					filterDownloads: async (filter: GetAllFilteredQuery) => {
						return await handleFecth(filter, false)
					},
					handleStateFilterByColumns: (props: filterByColumnsContructor) => {
						return handleStateFilterByColumns(props)
					},
					filterByColumnsFactory: (props: filterByColumnsContructor)=> {
						return filterByColumnsFactory(props)
					}
				}}
			/>
		</div>
	)
}

export default SellerTracking