import axios from 'axios'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiContext } from '../../context/apiContext'
import { TableV3 } from '../../components/Common/TableV3'
import { Button, Form, Modal, notification, Row, Spin, Tooltip, Typography } from 'antd'
import { AddCircleOutline, DeleteOutline, InfoOutlined, RefreshOutlined } from '@material-ui/icons'
import ModalContainer from '../../components/Containers/ModalContainer'
import { t } from 'i18next'
import { AdminDataContext } from '../../context/adminContext'
import { customerTokenAdd, customerTokenUpdate, deleteApiClient, updateApiClientAccess } from '../../services/admin_s'
import { usePost } from '../../hooks/usePost'
import ReactJson from 'react-json-view'
import InputContainer from '../../components/Containers/InputContainer'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../interfaces/table.interface'

const backendUrl = process.env.REACT_APP_NOT_BACKEND_URL

const filterByColumnsFactory = ({
	country,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
  country,
  range: { from: currentPage, to: rowsPerPages },
  searched: searchText,
  columns: [
    { id: 'created_at', find: false },
    { id: 'name', find: false },
    { id: 'email', find: false },
    { id: 'site', find: false },
    { id: 'status', find: false },
  ]
})

const TokenUsersV2 = () => {
  const { t } = useTranslation()
  const { userToken } = useContext(ApiContext)
  const { country } = useContext(AdminDataContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
  const [initRender, setInitRender] = useState(false)

  const [visible, setVisible] = useState(false)
  const [logs, setLogs] = useState<{} | any>();
  const [type, setType] = useState<"prod" | "sand" | "add" | "update">("prod")
  const [currentUserId, setCurrentUserId] = useState<string>('')
  const { fetchData: deleteRequest } = usePost(deleteApiClient)
  const { fetchData: addApiClient } = usePost(customerTokenAdd)
  const { fetchData: addCustomerToken } = usePost(customerTokenUpdate)
  const { fetchData: updateAccess, isLoading: isLoadingAccess } = usePost(updateApiClientAccess)
  const [form] = Form.useForm()

  const [refresh, setRefresh] = useState<Boolean>(false)

  const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
    const { data } = await axios.post(
        `${backendUrl}admin/user/tokensV2`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}`}}
    )

    setTotal(data?.total)
    if (saveState){
      setDataColumns(data?.data)
    } else {
      return data?.data ?? []
    }
  }

	const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

  const refreshTable = () => {
    setRefresh(!refresh)
  }

  const ViewPayload = (e: any, type: "prod" | "sand" | "add" | "update") => {
    setVisible(true)
    setLogs(e)
    setType(type)
  }

  const closeModal = () => {
    setVisible(false)
  }

  const selectModalType = (type: "prod" | "sand" | "add" | "update") => {
    switch (type) {
      case "prod":
        return {
          title: "Token de producción",
          component: logs ? <ReactJson src={logs} /> : "Data no disponible",
        }
      case "sand":
        return {
          title: "Token de prueba",
          component: logs ? <ReactJson src={logs} /> : "Data no disponible",
        }
      case "add":
        return {
          title: "Añadir cliente",
          component: <AddNewClient />,
        }
      case "update":
        return {
          title: "Agregar token",
          component: <UpdateClient user={logs} />,
        }
      default:
        break
    }
  }

  const AddNewClient = () => {
    const [form] = Form.useForm()

    const onFinish = async (e: object) => {
      const response = await addApiClient(e)
      if (response.data) {
        notification.success({ message: "Cliente api añadido" })
        refreshTable()
        setVisible(false)
        form.resetFields()
      } else {
        notification.error({
          message: "El cliente no ha podido ser agregado.",
        })
      }
    }

    return (
      <Form form={form} onFinish={onFinish}>
        <InputContainer
          required
          type="email"
          valueContainerName="email"
          placeholder="Correo eléctronico"
        />
        <InputContainer
          required
          type="text"
          valueContainerName="site"
          placeholder="Sitio Web"
        />
        <InputContainer type="submitButton" title="Añadir Cliente" />
      </Form>
    )
  }

  const UpdateClient = ({ user }: any) => {
    const [form] = Form.useForm()

    const onFinish = async (e: any) => {
      const request = {
        user: user,
        type: e?.type,
      }
      const response = await addCustomerToken(request)
      if (response.data) {
        notification.success({ message: "Token añadido" })
        refreshTable()
        setVisible(false)
        form.resetFields()
      } else {
        notification.error({ message: "El token no ha podido ser agregado." })
      }
    }

    return (
      <Form form={form} onFinish={onFinish}>
        <InputContainer
          required
          type="select"
          valueContainerName="type"
          placeholder="Tipo de token"
          optionsList={[
            { label: "Producción", value: "PRODUCTION" },
            { label: "Sandbox", value: "SANDBOX" },
          ]}
        />
        <InputContainer type="submitButton" title="Agregar Token" />
      </Form>
    )
  }

  const DeleteApiClient = async (userId: string) => {
    Modal.confirm({
      title: "Confirmar",
      content:
        "Seguro que desea eliminar a este usuario? los tokens de prueba y producción se perderán de nuestro registro, pero seguirán funcionando.",
      cancelText: "Cancelar",
      async onOk() {
        const { error } = await deleteRequest({ user: userId })
        if (!error) {
          refreshTable()
          notification.success({ message: "Cliente api eliminado" })
        } else {
          notification.error({
            message: "No se ha eliminado, intenta nuevamente",
          })
        }
      },
    })
  }

  const updateTokenData = async () => {
    try {
      const newData = form.getFieldValue('access')
      await updateAccess({
        type,
        access: newData,
        user: currentUserId
      })
      notification.success({
        message: 'Token actualizado correctamente'
      })
    } catch (error) {
      notification.error({
        message: 'Ha ocurrido un error'
      })
    }
  }

  useEffect(() => {
    form.setFieldsValue({ access: logs?.access || undefined })
  }, [currentUserId]) //eslint-disable-line

  useEffect(() => {
    if (!initRender){
      (async () => {
        setIsRefetching(true)
        await handleFecth(filterByColumns)
        setIsRefetching(false)
        setInitRender(true)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 
  
  useEffect(() => {
    if (initRender){
      (async () => {
        setIsRefetching(true)
        await handleFecth(filterByColumns)
        setIsRefetching(false)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns]) 

	useEffect(() => {
		if (initRender){
		  	(async () => {
				setCurrentPage(1)
				const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
				setFilterByColumns(customFilters)
		  	})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [country, refresh])

  return (
    <>
        <Row gutter={[10, 10]} style={{ maxHeight: "100%", overflowY: "scroll", overflowX: "scroll" }} >
            <div >
                <TableV3
                    title={t('TokenUsers.Table.Title')}
                    tableType="tracking"
                    data={dataColumns ?? []}
                    total={total}
                    isLoading={refetching}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRowsPerPage={(row) => setRowPerPages(row)}
                    config={{ disableFilters: true }}
                    schemaDownload={downloads}
                    columns={columns(setCurrentUserId, ViewPayload, DeleteApiClient)}
                    optionalContent={{ 
                        options: (
                            <>
                                <Button onClick={()=> ViewPayload({}, "add")}>
                                    Añadir Cliente
                                </Button>
                                <div style={{ marginLeft: '4px'}}>
                                    <Tooltip title="Refrescar datos">
                                        <RefreshOutlined onClick={()=> refreshTable()}/>
                                    </Tooltip>
                                </div>
                            </>
                        )
                    }}
                    stateValues={{
                        country,
                        currentPage,
                        rowsPerPages,
                        searchText,
                        filterByColumns,
                    }}
                    filters={{
                        filterBySearchText(text: string) {
                            setSearchText(text)
                        },
                        filterByColumns(filter: GetAllFilteredQuery) {
                            setFilterByColumns(filter)
                        },
                        filterDownloads: async (filter: GetAllFilteredQuery) => {
                            return await handleFecth(filter, false)
                        },
                        handleStateFilterByColumns: (props: filterByColumnsContructor) => {
                            return handleStateFilterByColumns(props)
                        },
                        filterByColumnsFactory: (props: filterByColumnsContructor)=> {
                            return filterByColumnsFactory(props)
                        } 
                    }}
                />
            </div>

            <ModalContainer
                title={`${selectModalType(type)?.title || ""}`}
                visible={visible}
                onCancel={closeModal}
                footer={false}
            >
                <div className="JSONViewer" style={{ overflow: 'scroll' }}>
                    {selectModalType(type)?.component}
                </div>
                <div>
                    <br />
                    <Form form={form}>
                        <Spin spinning={isLoadingAccess}>
                            <Typography style={{ marginLeft: '0.3rem' }}><strong>Acceso del token</strong></Typography>
                            <InputContainer
                                type="select"
                                valueContainerName="access"
                                placeholder="Acceso"
                                multiple={true}
                                style={{
                                    padding: '0.2rem'
                                }}
                                optionsList={[
                                    { label: "Plugin WooCommerce", value: "WORDPRESS" },
                                    { label: "Api", value: "API" },
                                ]}
                            />
                            <InputContainer
                                disabled={false}
                                type="button"
                                title="Actualizar"
                                onClick={updateTokenData}
                            />
                        </Spin>
                    </Form>
                    <br />
                </div>
            </ModalContainer>
      </Row>
    </>
  )
}

const columns =  (
    setCurrentUserId: any,
    ViewPayload: any,
    DeleteApiClient: any
) => {
    return [
        {
            title: t('TokenUsers.Table.Column.Date'),
            dataIndex: 'created_at',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a: any, b: any) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
            render: (created_at: Date) => created_at && moment(created_at).format("LL"),
        },
        {
            title: t('TokenUsers.Table.Column.Name'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => {
                return (
                  <div style={{ cursor: "pointer" }}>
                    <Tooltip title={rowData?.user || "No disponible - auth"}>
                      {rowData.name ? rowData.name : "No disponible"}
                    </Tooltip>
                  </div>
                )
            },
        },
        {
            title: t('TokenUsers.Table.Column.Email'),
            dataIndex: 'email',
            align: 'center',
            render: (email: string) => email,
        },
        {
            title: t('TokenUsers.Table.Column.Site'),
            dataIndex: 'site',
            align: 'center',
            render: (site: string) => site,
        },
        {
            title: t('TokenUsers.Table.Column.Status'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => {
                return (
                    <div
                        style={{
                            ...badgeStyle,
                            textAlign: "center",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                        }}
                    >
                        <InfoOutlined style={{ color: "#008fbf" }} />
                        {rowData?.status || "NO_TIENE_TOKEN"}
                    </div>
                )
            },
        },
        {
            title: t('TokenUsers.Table.Column.ProdToken'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => {
                return (
                    <InputContainer
                        style={{ width: 'auto'}}
                        disabled={!rowData.prod_token ? true : false}
                        type="button"
                        title="Ver Token"
                        onClick={() => {
                            setCurrentUserId(rowData?.user)
                            ViewPayload(rowData?.prod_token || {}, "prod")
                        }}
                    />
                )
            },
        },
        {
            title: t('TokenUsers.Table.Column.SandboxToken'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => {
                return (
                    <InputContainer
                        style={{ width: 'auto'}}
                        disabled={!rowData.sandbox_token ? true : false}
                        type="button"
                        title="Ver Token"
                        onClick={() =>{
                            setCurrentUserId(rowData?.user)
                            ViewPayload(rowData?.sandbox_token || {}, "sand")
                        }}
                    />
                )
            },
        },
        {
            title: t('TokenUsers.Table.Column.Actions'),
            dataIndex: '',
            align: 'center',
            render: (rowData: any) => {
                return (
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <Tooltip title="Agregar Token">
                            <AddCircleOutline
                                style={{ cursor: "pointer" }}
                                onClick={() => ViewPayload(rowData.user, "update")}
                            />
                        </Tooltip>
                        <Tooltip title="Borrar Cliente">
                            <DeleteOutline
                                style={{ cursor: "pointer" }}
                                onClick={() => DeleteApiClient(rowData.user)}
                            />
                        </Tooltip>
                    </div>
                )
            },
        },
    ]
}


export const downloads = (data: any[])=> {
    return data.map((item) => ({
        date: new Date(item.created_at).toLocaleString(),
        name: item.name,
        email: item.email,
        site: item.site,
        status: item?.status || "NO_TIENE_TOKEN",
    })) 
}

const badgeStyle = {
    display: "flex",
    padding: "5px 10px",
    background: "#25fee54c",
    borderRadius: "10px",
}

export default TokenUsersV2