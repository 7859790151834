import {
    Col,
    Row,
} from "antd";
import React from "react";
import { UserItemI } from "./SearchItem";
import AdvancedOptions from "../Components/AdvancedOptions";
import InputSearch from "../Components/InputSearch";
import { AdvancedCriteria, prefrencesMenu } from "../interfaces";
import SearchResults from "./SearchResults";
import AdvancedOptionsModal from "../Components/Modals/AdvancedOtionsModal";
import { useUserSearch } from '../../../../hooks/useUserSearch';

interface Props {
    nextStep: () => void;
    prevStep: () => void;
    persistQuery: (query: any) => void;
    persistResults: (results: any[] | null) => void;
    searchQuery: AdvancedCriteria | null;
    searchResults: any[] | null;
    newUserFound: (user: UserItemI) => void;
    getFullUser: (user: string) => void;
    handleMenu: (item: prefrencesMenu) => void;
}

interface Styles {
    listContainer: React.CSSProperties;
}

export const styles: Styles = {
    listContainer: {
        marginTop: '24px',
        width: '100%'
    }
}

const UserList: React.FC<Props> = ({
    nextStep,
    persistQuery,
    persistResults,
    searchQuery,
    searchResults,
    newUserFound,
    getFullUser,
    handleMenu
    
}) => {
    const {
        userListSearch,
        inputValue,
        advancedCriteria,
        setAdvancedCriteria,
        isMobile,
        advancedModal,
        loadingAdvanced,
        loadingSearch,
        loadingPaste,
        handlePaste,
        onChangeSwitchAdvanced,
        searchAdvancedOption,
        getSearchCriteria,
        cleanResults,
        handleChangeInput,
        persistData,
        manageAdvancedModal,
      } = useUserSearch(searchQuery, searchResults, persistQuery, persistResults);
   
/*
    useEffect(() => {
        if (!searchResults) {
            getSearchCriteria(true)
        }
        //eslint-disable-next-line
    }, [])
*/
    const onClickNextStep = () =>{
        handleMenu('profile')
        nextStep()
        document.getElementById('drenvio-searchv2-profile-default')?.click()
    }

    return (

        <Row>
            <InputSearch
                inputValue={inputValue}
                advancedCriteria={advancedCriteria}
                setAdvancedCriteria={setAdvancedCriteria}
                persistData={persistData}
                searchAdvancedOption={searchAdvancedOption}
                getSearchCriteria={getSearchCriteria}
                handlePaste={handlePaste}
                handleChangeInput={handleChangeInput}
                manageModal={manageAdvancedModal}
            />
            <Row gutter={[isMobile ? 0 : 20, 0]} style={styles.listContainer}>
                <Col xs={0} sm={0} md={0} lg={0} xl={5} xxl={5}>
                    <AdvancedOptions
                        advancedCriteria={advancedCriteria}
                        onChangeSwitchAdvanced={onChangeSwitchAdvanced}
                    />

                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={19} xxl={19}>
                    <SearchResults
                        searchQuery={searchQuery}
                        searchResults={searchResults}
                        loadingAdvanced={loadingAdvanced}
                        loadingSearch={loadingSearch}
                        loadingPaste={loadingPaste}
                        nextStep={onClickNextStep}
                        persistData={persistData}
                        cleanResults={cleanResults}
                        newUserFound={newUserFound}
                        getFullUser={getFullUser}
                        userListSearch={userListSearch}
                    />
                </Col>
            </Row>
            <AdvancedOptionsModal 
                visible={advancedModal}
                advancedCriteria={advancedCriteria}
                manageModal={manageAdvancedModal}
                onChangeSwitchAdvanced={onChangeSwitchAdvanced}
            />
        </Row>
    );
};

export default UserList;
