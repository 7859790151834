import {
  Badge,
  Button,
  Col,
  Popconfirm,
  Row,
  Tooltip,
  notification,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { Table } from "../../components/Common/Table";
import { CustomerDataI } from "./Sells";
import { ApiContext } from "../../context/apiContext";
import axios from "axios";
import { DeleteOutline, MessageOutlined, RefreshOutlined } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { CountriesFlag } from "../../components/CountriesFlag";
import { formatPrice2 } from "../../utils/prices";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { EyeOutlined } from "@ant-design/icons";
import ModalContainer from "../../components/Containers/ModalContainer";

const perBatch = 50;

const MyCustomers = ({ user }: { user: string }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [batchesFetched, setBatchesFetched] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [customerData, setCustomerData] = useState<Array<CustomerDataI>>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tracking, setTracking] = useState<Array<{ date: Date, sellerId: string, sellerEmail: string }>>([]);
  const [userSumatory, setSumatory] = useState<{
    prevMonth: number;
    currentMonth: number;
    totalCustomers: number;
  }>({ prevMonth: 0, currentMonth: 0, totalCustomers: 0 });
  const { backendURL, userToken } = useContext(ApiContext);
  const { user: SellerId } = useAuth0();

  const getData = async (reload?: boolean) => {
    setIsLoading(true);
    const response = await axios.get(
      `${backendURL}admin/getUsersForSeller/${reload ? 0 : batchesFetched}/${
        reload ? perBatch : (batchesFetched + 1) * perBatch
      }/-1/${user}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
    if (response.status === 200) {
      setCustomerData(response.data);
      setBatchesFetched((prevBatch) => prevBatch + 1);
    }
    setIsLoading(false);
  };

  const getSumatory = async () => {
    setIsLoading(true);
    const response = await axios.get(
      `${backendURL}admin/sellerSumatory/${user}`,
      {
        headers: { Authorization: `Bearer ${userToken}` },
      }
    );
    if (response.status === 200) {
      setSumatory({
        prevMonth: response?.data?.prevSumTotal?.count ?? 0,
        currentMonth: response?.data?.currentSumTotal?.count ?? 0,
        totalCustomers: response?.data?.totalCustomers ?? 0
      });
    }
    setIsLoading(false);
  };

  const handlePageChange = (page: any) => {
    if ((page + 1) * rowsPerPage === perBatch * batchesFetched) {
      getData();
    }
  };

  const onDelete = async (rowData: any) => {
    try {
      setIsLoading(true);
      const response = await axios.delete(
        `${backendURL}admin/deleteCustomer/${rowData.customerId}`,
        { headers: { Authorization: `Bearer ${userToken}` } }
      );
      if (response.status === 200) {
        getData(true);
        setIsLoading(false);
        return notification.success({
          message: "Cliente desasignado correctamente",
        });
      }
      setIsLoading(false);
      return notification.success({
        message: "Error al desasignar cliente",
      });
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onContacted = async (rowData: any) => {

    const { customerId, tracking } = rowData;

    if(tracking && tracking.length > 0) {
      const lastDate = moment(tracking[tracking.length - 1].date).format("YYYY-MM-DD");
      const today = moment().format("YYYY-MM-DD");

      if(lastDate === today){
        return notification.info({
          message: "Ya contactaste a este cliente hoy, vuelve a marcarlo mañana."
        })
      }
    }

    try {
      await axios.post(
        `${backendURL}admin/createMovement`,
        { customerId, sellerId: SellerId?.sub },
        { headers: { Authorization: `Bearer ${ userToken }` } }
      )

      await reloadData()

      return notification.success({
        message: "Cliente contactado hoy",
      })
    } catch (error) {
      console.log('Error at onContacted',error)
      return notification.error({
        message: "Error al marcar como contactado"
      })
    }
  }

  const openTrackingModal = (rowData: any) => {
    const last15days = rowData?.tracking?.slice(-15) ?? []
    setTracking(last15days);
    setOpenModal(true)
  }

  const reloadData = async () => {
    setBatchesFetched(0);
    await getData(true);
    await getSumatory();
  };

  useEffect(() => {
    getData();
    getSumatory();
  }, []); //eslint-disable-line

  const columns = [
    {
      title: "Email",
      field: "profile.email",
    },
    {
      title: "Pais",
      field: "userCountry.app_country",
      render: (rowData: any) => {
        return (
          <>
            {
              <CountriesFlag
                country={rowData?.userCountry?.app_country}
                width="30"
              />
            }
          </>
        );
      },
    },
    {
      title: "Depositado mes pasado",
      render: (rowData: any) => {
        return <>$ {rowData?.movements?.movementsPreviousMonth ?? "0"}</>;
      },
    },
    {
      title: "Depositado mes actual",
      render: (rowData: any) => {
        return <>$ {rowData?.movements?.movementsThisMonth ?? "0"}</>;
      },
    },
    {
      title: "Envios mes pasado",
      render: (rowData: any) => {
        return <>{rowData?.shipments?.shipmentsPreviousMonth ?? "0"}</>;
      },
    },
    {
      title: "Envios mes actual",
      render: (rowData: any) => {
        return <>{rowData?.shipments?.shipmentsThisMonth ?? "0"}</>;
      },
    },
    {
      title: "Tipo de cliente",
      field: "type",
      render: (rowData: any) => {
        return (
          <Badge
            status="success"
            style={{
              margin: "20px 0",
              background: "#59ff007e",
              color: "#007a00",
              padding: "2px 10px",
            }}
            text={rowData.type}
          />
        );
      },
    },
    {
      title: "Acciones",
      render: (rowData: any) => {
        return (
          <Row gutter={[10,10]}>
            <Col span={8}>
              <Tooltip title="Desasignar cliente">
                <Popconfirm
                  title="¿Quieres desasignar este cliente?"
                  onConfirm={() => onDelete(rowData)}
                >
                  <IconButton>
                    <DeleteOutline />
                  </IconButton>
                </Popconfirm>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title="Marcar como contactado hoy">
                <Popconfirm onConfirm={() => onContacted(rowData)} title={"¿Contactaste a este cliente hoy?"}>
                  <IconButton>
                    <MessageOutlined />
                  </IconButton>
                </Popconfirm>
              </Tooltip>
            </Col>
            <Col span={8}>
              <Tooltip title="Ver seguimiento de cliente">
                <IconButton onClick={() => openTrackingModal(rowData)}>
                  <EyeOutlined />
                </IconButton>
              </Tooltip>
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <Row>
      <ModalContainer
        title="Seguimiento del usuario"
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        footer={<></>}
      >
        {(tracking.length === 0 && <p>No hay datos ...</p>) ||
					(tracking.length > 0 &&
						tracking.map((item) => (
							<div style={{ padding: "10px" }} key={item.sellerId}>
								<p style={{ margin: "0", padding: "0" }}>
									<span style={{ fontWeight: "600" }}>Vendedor: </span> {item.sellerEmail}
								</p>
								<p style={{ margin: "0", padding: "0" }}>
									<span style={{ fontWeight: "600" }}>Últ. Contacto: </span>{" "}
									{moment(item.date).format("YYYY-MM-DD HH:mm:ss")}
								</p>
							</div>
						)))}
      </ModalContainer>
      <Col span={24}>
        <Table
          title="Mis clientes asignados"
          data={customerData ?? []}
          columns={columns ?? []}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={(row) => setRowsPerPage(row)}
          component={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button type="primary" style={{ marginRight: "15px" }}>
                Mes Pasado $ {formatPrice2(userSumatory.prevMonth, "mxn")}
              </Button>
              <Button type="primary" style={{ marginRight: "15px" }}>
                Mes Actual $ {formatPrice2(userSumatory.currentMonth, "mxn")}
              </Button>
              <Button type="primary" style={{ marginRight: "15px" }}>
                Cant. Clientes {userSumatory?.totalCustomers ?? 0}
              </Button>
            </div>
          }
          actions={[
            {
              icon: () => <RefreshOutlined />,
              tooltip: "Refrescar datos",
              isFreeAction: true,
              //@ts-ignore
              onClick: () => reloadData(),
            },
          ]}
          isLoading={isLoading}
        />
      </Col>
    </Row>
  );
};

export default MyCustomers;
