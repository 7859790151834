import React from "react";
import { Button, Modal } from "antd";
import Shipments from "../../pages/DashboardSections/Records/Shipments";
import Movements from "../../pages/DashboardSections/Records/Movements";
import { modalSizes } from "../Containers/ModalContainer";
import ShipmentsByUser from "../../pages/DashboardSections/Records/ShipmentsByUser";
import Facturar from "../../pages/DashboardSections/Users/Facturas";
import MovementsV2 from "../../pages/DashboardSections/Records/MovementsV2";

interface ModalProps {
	isModalOpen: {
		visible: boolean;
		currentTable: string;
		user?: string;
	};
	user: string;
	setIsModalOpen: React.Dispatch<React.SetStateAction<any>>;
}
const ModalTable: React.FC<ModalProps> = ({ isModalOpen, setIsModalOpen, user }) => {
	const handleCancel = () => {
		setIsModalOpen({ visible: false, selector: "Recharge", user: undefined });
	};
	const closeModal = () => {
		setIsModalOpen(false);
	};
	return (
		<>
			<Modal
				width={isModalOpen.currentTable === "shipments" ? modalSizes.EXTRABIG : 1000}
				visible={isModalOpen?.visible}
				onCancel={handleCancel}
				footer={[<Button onClick={closeModal}>Cerrar</Button>]}
			>
				{isModalOpen.currentTable === "shipments" ? (
					<>{!isModalOpen?.user ? <Shipments /> : <ShipmentsByUser user={isModalOpen?.user} />}</>
				) : isModalOpen.currentTable === "facturas" ? (
					<>
						<Facturar />
					</>
				) : !isModalOpen?.user ? (
					<Movements />
				) : (
					// <MovementsByUser user={isModalOpen.user as string} />
					<MovementsV2 user={isModalOpen.user as string} />
				)}
			</Modal>
		</>
	);
};

export default ModalTable;
