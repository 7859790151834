import TabsContainer from '../../components/Containers/TabsContainer'
import TracingV2 from './TracingV2'

const TrackingSection = () => {
  return (
    <section className='dashboardSection'>
      <TabsContainer
        titlesAndContent={{
          Seguimiento: {
            component: (
              <TracingV2 />
            ),
            description: 'Seguimiento de usuarios',
          }
        }}
      />
    </section>
  )
}

export default TrackingSection

