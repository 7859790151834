import { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { AdminDataContext } from "../context/adminContext";
import { AdvancedCriteria, CriteriaType } from "../pages/DashboardSections/UsersBeta/interfaces";
import { usePost } from "./usePost";
import { getUsersByAdvancedCriteria, getUsersBySearchCriteria, getUserSearchByPasteEmail } from "../services/user_d";

export const useUserSearch = (
  searchQuery: AdvancedCriteria | null,
  searchResults: any[] | null,
  persistQuery: (query: any) => void,
  persistResults: (results: any[] | null) => void
) => {
  const { country } = useContext(AdminDataContext);
  const [userListSearch, setUserListSearch] = useState<any>(null);
  const [inputValue, setInputValue] = useState("");
  const [actualPage] = useState(1);
  const [advancedModal, setAdvancedModal] = useState(false);
  const [advancedCriteria, setAdvancedCriteria] = useState<AdvancedCriteria>({
    criteria: "email",
    text: "",
  });

  const { fetchData: fetchAdvanced, isLoading: loadingAdvanced } = usePost(
    getUsersByAdvancedCriteria
  );
  const { fetchData: fetchSearch, isLoading: loadingSearch } = usePost(
    getUsersBySearchCriteria
  );
  const { fetchData: fetchPaste, isLoading: loadingPaste } = usePost(
    getUserSearchByPasteEmail
  );
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  useEffect(() => {
    if (searchQuery !== null) {
      setAdvancedCriteria(searchQuery);
    }

    if (searchQuery && searchQuery.text) {
      setInputValue(searchQuery.text);
    }

    if (searchResults !== null && searchResults.length > 0) {
      setUserListSearch(searchResults);
    }
    //eslint-disable-next-line
  }, []);
/*
  useEffect(() => {
    if (!searchResults) {
      getSearchCriteria(true);
    }
    //eslint-disable-next-line
  }, []);
*/
  const handlePaste = (event: any) => {
    if (advancedCriteria.criteria === "search") {
      getSearchPaste(event.clipboardData.getData("text"));
    }

    setAdvancedCriteria({
      ...advancedCriteria,
      text: event.clipboardData.getData("text"),
    });
  };

  const onChangeSwitchAdvanced = (criteria: CriteriaType) => {
    setAdvancedCriteria({
      ...advancedCriteria,
      criteria,
    });
  };

  const searchAdvancedOption = async () => {
    try {
      const response = await fetchAdvanced(advancedCriteria);
      if (!response.error) {
        setUserListSearch(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchCriteria = async (emptyText?: boolean) => {
    try {
      const response = await fetchSearch({
        page: actualPage,
        limit: 10,
        country,
        text: emptyText ? undefined : inputValue,
      });
      if (!response.error) {
        setUserListSearch(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSearchPaste = async (email: string) => {
    try {
      const response = await fetchPaste({ email });
      if (!response.error) {
        setUserListSearch(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cleanResults = async () => {
    setAdvancedCriteria({
      ...advancedCriteria,
      text: "",
    });
    setInputValue("");
    setUserListSearch(null);
    persistData(false);
    getSearchCriteria(true);
  };

  const handleChangeInput = (e: any) => {
    setInputValue(e.target.value);
  };

  const persistData = (persist = true) => {
    if (persist) {
      if (userListSearch !== null) {
        persistQuery(advancedCriteria);
        persistResults(userListSearch);
      }
    } else {
      persistQuery(null);
      persistResults(null);
    }
  };

  const manageAdvancedModal = () => {
    setAdvancedModal(!advancedModal);
  };

  return {
    userListSearch,
    inputValue,
    advancedCriteria,
    setAdvancedCriteria,
    isMobile,
    advancedModal,
    loadingAdvanced,
    loadingSearch,
    loadingPaste,
    handlePaste,
    onChangeSwitchAdvanced,
    searchAdvancedOption,
    getSearchCriteria,
    cleanResults,
    handleChangeInput,
    persistData,
    manageAdvancedModal,
  };
};