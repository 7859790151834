import { t } from 'i18next'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Tag, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { ApiContext } from '../../../context/apiContext'
import { AdminDataContext } from '../../../context/adminContext'
import { TableV3 } from '../../../components/Common/TableV3'
import { Movement, MovementTypes } from '../../../interfaces/shipments.interface'
import { MovementStatus } from '../../../components/Common/MovementStatus'
import { formatPrice2 } from '../../../utils/prices'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../../interfaces/table.interface'

interface Props {
  user?: string
}

interface MovementsColumnsType {
  key: React.Key
  created_at: string
  profile: {
    user: string
    email: string
  }
  type: string
  description: string
  payment_id: string
  amount: number
  currency: string
  security: {
    after_mod: number 
    before_mod: number 
  }
  made_by: string
}

const filterByColumnsFactory = ({
	country,
  user,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
  isAdmin: user ? false : true,
  user: user ? user : undefined,
	country,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
    { id: 'user', find: false },
    { id: 'description', find: false },
    { id: 'payment_id', find: false },
    { id: 'created_at', find: false },
    { id: 'quantity', find: false },
    { id: 'beforeMod', find: false },
    { id: 'afterMod', find: false },
    { id: 'made_by', find: false },
    {
      id: 'type',
      find: true,
      filters: {
        sort: false,
        filter: true,
        filter_values: [
          'ENVIO',
          'SOBREPESO',
          'DESCUENTO',
          'RECARGA',
          'REEMBOLSO',
          'SINCRONIZACION DE SALDO',
          'NOTIFICATION',
          'REFERIDO',
          // 'ACTUALIZACIÓN DE USUARIO',
          // 'DESBLOQUEO DE USUARIO',
          // 'BLOQUEO DE USUARIO',
        ]
      }
    }
  ]
})

const MovementsV2 = ({ user }: Props) => {
  const { t } = useTranslation()
  const { userToken, backendURL } = useContext(ApiContext)
  const { country } = useContext(AdminDataContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, user, currentPage, rowsPerPages, searchText }))
	const [initRender, setInitRender] = useState(false)

  const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
    const { data } = await axios.post(
      `${backendURL}admin/movementsV2`, 
      filterByColumns, 
      { headers: { Authorization: `Bearer ${userToken}` }}
    )

    setTotal(data?.total)
    if (saveState) {
      setDataColumns(data?.data ?? [])
    } else {
      return data?.data ?? []
    }
  }

  const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

	useEffect(() => {
		if (!initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
				setInitRender(true)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [filterByColumns]) 
	
  useEffect(() => {
    if (initRender){
      (async () => {
        setCurrentPage(1)
        const customFilters = filterByColumnsFactory({ country, user, currentPage: 1, rowsPerPages })
        setFilterByColumns(customFilters)
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  return (
    <div>
      {' '}
      <TableV3
        title={t('Movements.Table.Title')}
        tableType="movements"
        isShipments
        data={dataColumns ?? []}
        total={total}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        schemaDownload={MovementsDownloads}
        columns={
            MovementsColumnV2(
            user,
            dataColumns ?? [],
          ) as ColumnsType<MovementsColumnsType>
        }
        stateValues={{
          user, 
          country,
          currentPage,
          rowsPerPages,
          searchText,
          filterByColumns,
        }}
        filters={{
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredQuery) => {
            return await handleFecth(filter, false)
          },
          handleStateFilterByColumns: (props: filterByColumnsContructor) => {
						return handleStateFilterByColumns(props)
					},
					filterByColumnsFactory: (props: filterByColumnsContructor)=> {
						return filterByColumnsFactory(props)
					} 
        }}
      />
    </div>
  )
}

const MovementsColumnV2 = (
  user: string | undefined,
  data: Movement[],
): ColumnsType<MovementsColumnsType>=> {
  const uniqueMovementsTypes = Array.from(new Set(data.map((item) => item.type))) as MovementTypes[]

  const typesMapUI = {
    ENVIO: t('Movements.Table.Columns.Type.Shipment'),
    SOBREPESO: t('Movements.Table.Columns.Type.Overweight'),
    DESCUENTO: t('Movements.Table.Columns.Type.Discount'),
    RECARGA: t('Movements.Table.Columns.Type.Recharge'),
    REEMBOLSO: t('Movements.Table.Columns.Type.Refund'),
    'SINCRONIZACION DE SALDO': t('Movements.Table.Columns.Type.Sync'),
    'NOTIFICATION': t('Movements.Table.Columns.Type.Notification'),
    'REFERIDO': t('Movements.Table.Columns.Type.Referral'),
    'ACTUALIZACIÓN DE USUARIO': t('Movements.Table.Columns.Type.UpdatedUser'),
    'DESBLOQUEO DE USUARIO': t('Movements.Table.Columns.Type.UnlockedUser'),
    'BLOQUEO DE USUARIO': t('Movements.Table.Columns.Type.BlockedUser')
  }

  const columns = [] as ColumnsType<MovementsColumnsType>
  if (!user){
    columns.push(
      {
        title: t('Movements.Table.Columns.User'),
        dataIndex: '',
        align: 'center',
        render: (rowData: any) => (
            <div style={{ cursor: 'pointer' }}>
                <Tooltip title={rowData?.profile?.user ?? ''}>{rowData?.profile?.email ?? ''}</Tooltip>
            </div>
        )
      },
    )
  }

  return [
    ...columns,
    {
        title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Movements.Table.Columns.Type')}</Tooltip>,
        dataIndex: 'type',
        align: 'center',
        showSorterTooltip: false,
        sorter: (a, b) => a.type.localeCompare(b.type),
        filters: uniqueMovementsTypes.map((status) => ({ text: typesMapUI[status], value: status })),
        onFilter: (value, record) => record.type.indexOf(value as string) === 0,
        render: (type) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <MovementStatus status={type}></MovementStatus>,
          </div>
        )
    },
    {
        title: t('Movements.Table.Columns.Description'),
        dataIndex: '',
        align: 'center',
        render: (rowData: any) => {
            return rowData.type === 'ENVIO' || (rowData.type === 'REEMBOLSO' && rowData.description !== 'Nuevo Movimiento') ? (
                    <>
                        <Tag
                            color={rowData.type === 'ENVIO' ? 'blue' : 'orange'}
                            style={{ marginTop: '5px' }}
                        >
                            Paquetería: {rowData.description.toUpperCase()}
                            <br />
                            {`N° de guia: ${rowData.payment_id}`}
                        </Tag>
                    </>
                ) : (
                    <>{rowData.description}</>
                )
        }
    },
    {
      title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Movements.Table.Columns.Date')}</Tooltip>,
      dataIndex: 'created_at',
      align: 'center',
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (createdAt: string) => new Date(createdAt).toLocaleString()
    },
    {
        title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Movements.Table.Columns.Quantity')}</Tooltip>,
        dataIndex: '',
        align: 'center',
        showSorterTooltip: false,
        sorter: (a, b) => a.amount - b.amount,
        render: (rowData: any) => (
            <>
                {rowData.type === 'SOBREPESO' || rowData.type === 'DESCUENTO'
                    ? '$-' + `$ ${formatPrice2(rowData.amount ?? 0, rowData.currency ?? '')}`.split('$')[1]?.trim()
                    : `$ ${formatPrice2(rowData.amount ?? 0, rowData.currency ?? '')}`.split('$')[1]?.trim()}
            </>
        )
    },
    {
        title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Movements.Table.Columns.PrevBalance')}</Tooltip>,
        dataIndex: '',
        align: 'center',
        showSorterTooltip: false,
        sorter: (a, b) => a.security.before_mod - b.security.before_mod,
        render: (rowData) => `$ ${formatPrice2(rowData.security.before_mod, rowData.currency)}`,
    },
    {
        title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Movements.Table.Columns.PostBalance')}</Tooltip>,
        dataIndex: '',
        align: 'center',
        showSorterTooltip: false,
        sorter: (a, b) => a.security.after_mod - b.security.after_mod,
        render: (rowData) => `$ ${formatPrice2(rowData.security.after_mod, rowData.currency)}`,
    },
    {
        title: t('Movements.Table.Columns.MadeBy'),
        dataIndex: '',
        align: 'center',
        render: (rowData) => rowData?.made_by ? rowData?.made_by?.email || 'Sistema' : 'Sistema',
    },
  ]
}

const MovementsDownloads = (data: any[])=> {
  return data.map(item => ({
      Usuario: item?.profile?.email ?? '',
      Tipo: item.type,
      Descripcion: item.type === 'ENVIO' || (item.type === 'REEMBOLSO' && item.description !== 'Nuevo Movimiento') ?
        `Paquetería: ${item?.description.toUpperCase()}. N° de guia: ${item?.payment_id}` : `${item?.description}`,
    'Fecha de creacion': new Date(item?.created_at).toLocaleDateString(),
    Cantidad: item.type === 'SOBREPESO' || item.type === 'DESCUENTO'
        ? '$-' + `$ ${formatPrice2(item?.amount, item?.currency)}`.split('$')[1]?.trim()
        : `$ ${formatPrice2(item.amount ?? 0, item.currency ?? '')}`.split('$')[1]?.trim(),
    'Saldo anterior': `$ ${formatPrice2(item?.security?.before_mod, item?.currency)}`,
    'Saldo posterior': `$ ${formatPrice2(item?.security?.after_mod, item?.currency)}`,
    'Realizado por': item?.made_by ? item?.made_by?.email || 'Sistema' : 'Sistema',
  }))
}

export default MovementsV2