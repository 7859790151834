import { Col, Row } from "antd";
import TabsContainer from "../../components/Containers/TabsContainer";
import { useEffect, useState } from "react";
import SellerTracking from "./SellerTracking";

const TrackingCustomerTabs = () => {

	const [tabContent, setTabContent] = useState({})

	const assignTabs = () => {
		let tabObject = {}

		tabObject = {
			"Seg. de Clientes": {
				component: <SellerTracking />,
				description: "Seguimiento de clientes con vendedores"
			}
		}
		setTabContent(tabObject)
	}

	useEffect(() => {
		assignTabs()
		//eslint-disable-next-line
	}, [])

	return (
		<Row gutter={[10,10]} className="dashboardSection">
			<Col span={24} style={{ overflowY:'scroll' }}>
				<TabsContainer titlesAndContent={tabContent} />
			</Col>
		</Row>
	)

}

export default TrackingCustomerTabs