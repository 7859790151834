import { Brightness1Outlined, CheckCircle } from "@material-ui/icons";
import { Tooltip } from "antd";
import moment from "moment";

const TrackingUser = (field: any) => {
    const activity = field?.userData[0]?.metadata?.activity;
    const checkedIcon = (confirm: any) => {
        switch (confirm) {
        case true:
            return <CheckCircle style={{ color: "green", fontSize: "25px" }} />;
        case false:
            return (
            <Brightness1Outlined
                style={{ color: "green", fontSize: "25px" }}
            />
            );
        case null:
        case undefined:
            return (
            <Brightness1Outlined
                style={{ color: "green", fontSize: "25px" }}
            />
            );
        default:
            return <CheckCircle style={{ color: "green", fontSize: "25px" }} />;
        }
    };

    return (
        <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
        >
        <Tooltip
            title={activity?.completedRegister ? "Registrado" : "Sin registro"}
        >
            <div style={{ margin: "0 2px" }}>
            {checkedIcon(activity?.completedRegister)}
            </div>
        </Tooltip>
        <Tooltip
            title={
            activity?.completedProfile
                ? "Perfil completado"
                : "Perfil incompleto"
            }
        >
            <div style={{ margin: "0 2px" }}>
            {checkedIcon(activity?.completedProfile)}
            </div>
        </Tooltip>
        <Tooltip title={activity?.OTP ? "Verificado" : "Sin verificar"}>
            <div style={{ margin: "0 2px" }}>{checkedIcon(activity?.OTP)}</div>
        </Tooltip>
        <Tooltip
            title={
            activity?.firstRecharge && activity?.firstRecharge !== null
                ? `Primera recarga ${moment(activity?.firstRecharge).format(
                    "LLL"
                )}`
                : `Aún no recarga`
            }
        >
            <div style={{ margin: "0 2px" }}>
            {checkedIcon(activity?.firstRecharge)}
            </div>
        </Tooltip>
        <Tooltip
            title={
            activity?.lastShipment && activity?.lastShipment !== null
                ? `Último envío ${moment(activity?.lastShipment).format(
                    "LLL"
                )}`
                : `Aún no hace envíos`
            }
        >
            <div style={{ margin: "0 2px" }}>
            {checkedIcon(activity?.lastShipment)}
            </div>
        </Tooltip>
        </div>
    );
};

export default TrackingUser