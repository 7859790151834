import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { TableV3 } from '../../../../components/Common/TableV3'
import { AllColumnsType } from '../../../../interfaces/partner.interface'
import { useTranslation } from 'react-i18next'
import { fulfillProfilesColumn } from './fulfillSchemaColumns'
import { fulfillProfilesDownloads } from './fulfillSchemaDownloads'
import { ApiContext } from '../../../../context/apiContext'
import { AdminDataContext } from '../../../../context/adminContext'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../../../interfaces/table.interface'

const backendUrlApiClientes = process.env.REACT_APP_NOT_BACKEND_URL

const filterByColumnsFactory = ({
	country,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
  country,
  range: {
    from: currentPage,
    to: rowsPerPages,
  },
  searched: searchText,
  columns: [
    { id: 'createdAt', find: false },
    { id: 'name', find: false },
    { id: 'email', find: false },
    { id: 'updatedAt', find: false },
  ],
})

const FulfillProfiles = () => {
  const { t } = useTranslation()
  const { userToken } = useContext(ApiContext)
  const { country } = useContext(AdminDataContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
  const [initRender, setInitRender] = useState(false)

  const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
    const { data } = await axios.post(
      `${backendUrlApiClientes}users/allFulfillUsers`, 
      filterByColumns, 
      { headers: { Authorization: `Bearer ${userToken}`}}
    )

    setTotal(data?.total)
    if (saveState){
      setDataColumns(data?.data)
    } else {
      return data?.data ?? []
    }
  }

	const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

  useEffect(() => {
    if (!initRender){
      (async () => {
        setIsRefetching(true)
        await handleFecth(filterByColumns)
        setIsRefetching(false)
        setInitRender(true)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 
  
  useEffect(() => {
    if (initRender){
      (async () => {
        setIsRefetching(true)
        await handleFecth(filterByColumns)
        setIsRefetching(false)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns]) 

	// useEffect(() => {
	// 	if (initRender){
	// 	  	(async () => {
	// 			setCurrentPage(1)
	// 			const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
	// 			setFilterByColumns(customFilters)
	// 	  	})()
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [country])

  return (
    <div>
      {' '}
      <TableV3
        title={t('FulfillmentProfiles.Table.Title')}
        tableType="fulfillProfiles"
        data={dataColumns ?? []}
        total={total}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        config={{ disableFilters: true }}
        schemaDownload={fulfillProfilesDownloads}
        columns={fulfillProfilesColumn(dataColumns ?? []) as ColumnsType<AllColumnsType>}
        stateValues={{
          country,
          currentPage,
          rowsPerPages,
          searchText,
          filterByColumns,
        }}
        filters={{
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredQuery) => {
            return await handleFecth(filter, false)
          },
          handleStateFilterByColumns: (props: filterByColumnsContructor) => {
						return handleStateFilterByColumns(props)
					},
					filterByColumnsFactory: (props: filterByColumnsContructor)=> {
						return filterByColumnsFactory(props)
					}
        }}
      />
    </div>
  )
}

export default FulfillProfiles