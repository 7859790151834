import { Button, Col, Row, Tooltip } from 'antd'
import { Table } from '../../components/Common/Table'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { ApiContext } from '../../context/apiContext'
import { AddCircleOutline, ArrowDownwardOutlined, ArrowUpwardOutlined, RefreshOutlined, VisibilityOutlined } from '@material-ui/icons'
import UserView from './UserView'
import ModalContainer from '../../components/Containers/ModalContainer'
import AssignCustomer from './AssignCustomer'
import FindUser from './FindUser'
import { CountriesFlag } from '../../components/CountriesFlag'

export interface CustomerDataI {
  _id: string
  amountThisMonth: number
  movements?: { amountThisMonth?: number }[]
  shipments?: { shipmentsThisMonth: number }
  profile: { email: string, phone: string, name: string }
  email?: string,
  phone?: string,
  name?: string,
  user?: string
}

const perBatch = 100

const Sells = () => {

  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ batchesFetched, setBatchesFetched ] = useState<number>(0)
  const [ rowsPerPage, setRowsPerPage ] = useState<number>(5)
  const [ openModal, setOpenModal ] = useState<{ type: string, open: boolean, data: object }>({ type: '', open: false, data: {} })
  const [ customerData, setCustomerData ] = useState<Array<CustomerDataI>>([])

  const { backendURL, userToken } = useContext(ApiContext)

  const selectModal = () => {
    switch (openModal.type) {
      case 'view':
        return {
          title: "Vendedor Asignado",
          component: <UserView refetch={getData} data={openModal.data as CustomerDataI} setOpenModal={setOpenModal} openModal={openModal} />
        }
      case 'assign':
        return {
          title: "Asignar cliente",
          component: <AssignCustomer data={openModal.data as CustomerDataI} setOpenModal={setOpenModal} openModal={openModal}/>
        }
      default:
        return {
          title:"",
          component: <></>
        }
    }
  }

  const getData = async(reload?: boolean, filterByPrice?: number) => {
    setIsLoading(true)
    const response = await axios.get(`${backendURL}admin/getUsersForSalesPurposes/${reload ? 0 : batchesFetched}/${reload ? perBatch : (batchesFetched + 1) * perBatch}/${filterByPrice ?? -1}`, {
      headers: { Authorization: `Bearer ${ userToken }` }
    })
    if(response.status === 200){
      setCustomerData(response.data)
      setBatchesFetched((prevBatch) => prevBatch + 1)
    }
    setIsLoading(false)
  }

  const handlePageChange = (page: any) => {
    if ((page + 1) * rowsPerPage === perBatch * batchesFetched) {
      getData()
    }
  }

  useEffect(() => {
    getData()
    //eslint-disable-next-line
  }, [])

  const columns = [
    {
      title: "Email",
      render: (rowData: any) => {
        return (
          <>
            {
              rowData?.email ?? rowData?.profile[0]?.email ?? "NA"
            }
          </>
        )
      }
    },
    {
      title: "Pais",
      field: "userCountry.app_country",
      render: (rowData: any) => {
        return (
          <>
            { <CountriesFlag country={rowData?.userCountry?.app_country ?? "MX"} width="30" /> }
          </>
        )
      }
    },
    {
      title: "Monto depositado en el mes",
      render: (rowData: any) => {
        return (
          <>
            $ {
              rowData?.amountThisMonth ??  rowData?.movements[0]?.amountThisMonth ?? "0"
            }
          </>
        )
      } 
    },
    {
      title: "Envios mes pasado",
      render: (rowData: any) => {
        return (
          <>
            {
              rowData?.shipments[0]?.shipmentsPreviousMonth ?? "0"
            }
          </>
        )
      } 
    },
    {
      title: "Envios mes actual",
      render: (rowData: any) => {
        return (
          <>
            {
              rowData?.shipments[0]?.shipmentsThisMonth ?? "0"
            }
          </>
        )
      } 
    },
    {
      title: "Vendedor asignado",
      render: (rowData: any) => {
        return (
          <>
            {
              rowData?.customerData ? rowData?.customerData[0]?.sellerProfile?.email : "NO ASIGNADO"
            }
          </>
        )
      }
    },
    {
      title: 'Acciones',
      render: (rowData: any) => {
        return (
          <Row>
            <Col span={12}>
              <Tooltip title="Asignar cliente">
                <AddCircleOutline style={{ cursor:'pointer' }} onClick={() => setOpenModal({ type:'assign', open: true, data: rowData })} />
              </Tooltip>
            </Col>
            <Col span={12}>
              <Tooltip title="Ver vendedor asignado">
                <VisibilityOutlined style={{ cursor:'pointer' }} onClick={() => setOpenModal({ type:'view', open: true, data: rowData })} />
              </Tooltip>
            </Col>
          </Row>
        )
      }
    }
  ]

  return (
    <>
    <Row gutter={[10,10]}>
        <Col span={24}>
            <Table
              title='Lista de clientes'
              data={customerData ? customerData : []}
              columns={columns}
              isLoading={isLoading}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={(row) => setRowsPerPage(row)}
              component={
                <Row gutter={[10,2]}>
                  <Col span={18}>
                    <FindUser customerData={customerData} setCustomerData={setCustomerData} />
                  </Col>
                  <Col span={3}>
                    <Tooltip title="Filtrar mayor a menor monto">
                      <Button onClick={() => getData(true, -1)} icon={<ArrowUpwardOutlined />} />
                    </Tooltip>
                  </Col>
                  <Col span={3}>
                    <Tooltip title="Filtrar menor a mayor monto">
                      <Button onClick={() => getData(true, 1)} icon={<ArrowDownwardOutlined />} />
                    </Tooltip>
                  </Col>
                </Row>
              }
              actions={[
                {
                  icon: () => <RefreshOutlined />,
                  tooltip: 'Refrescar datos',
                  isFreeAction: true,
                  //@ts-ignore
                  onClick: () => {
                    setBatchesFetched(0)
                    getData(true)
                  },
                },
              ]}
            />
        </Col>
      </Row>
      <ModalContainer
        customWidth='30rem'
        title={selectModal().title}
        footer={<></>}
        visible={openModal.open}
        onCancel={() => setOpenModal({ ...openModal, open: false })}
      >
        { selectModal().component }
      </ModalContainer>
    </>
  )
}

export default Sells