import { Referral, Profile, Withdrawal, PendingCommissions } from "../../../../interfaces/partner.interface"

export const profilesDownloads = (data: Profile[])=> {
    return data.map((item: Profile) => ({
        firstActivity: new Date(item.partners.createdAt).toLocaleString(),
        name: item.name,
        email: item.email,
        lastActivity: new Date(item.partners.updatedAt).toLocaleString()
    })) 
}

export const sellerTrackingDownloads = (data: any) => {
    return data.map((item: any)=> {
        return {
            cliente: item?.customerEmail,
            fechaDeContacto: new Date(item.date).toLocaleString(),
            vendedor: item?.sellerEmail
        }
    })
}

export const referralDownloads = (data: Referral[])=> {
    return data.map((item: Referral) => ({
        createdAt: new Date(item.createdAt).toLocaleString(),
        owner: item.profile.email,
        referral: item.referral.email,
        status: item.referral.status
    })) 
}

export const withdrawalDownloads = (data: Withdrawal[])=> {
    return data.map(item => ({
        createdAt: new Date(item.createdAt).toLocaleString(),
        owner: item.profile.email,
        referral: item.referral.email,
        status: item.status,
        withdrawalType: item.withdrawalType,
        shipments: item.shipments.length,
        amount: item.amount.toFixed(2),
    }))
}

export const PendingCommissionsDownloads = (data: PendingCommissions[])=> {
    return data.map(item => ({
        createdAt: new Date(item.createdAt).toLocaleString(),
        owner: item.profile.email,
        referral: item.referral.email,
        shipments: item.referral.shipments.length,
        amount: item.referral.amount.toFixed(2)
    })) 
}