import { FC } from "react"
import { assingDashboardLogos } from "../../utils";
import useDevice from "../../../../../hooks/useDevice";
import { PreferencesCarriersV2 } from "../../interfaces";
import { Tag, Typography } from "antd";
import { carrierLabels } from "../../../../../services/records";

interface props {
    carrier: PreferencesCarriersV2;
}

interface Styles {
    title: React.CSSProperties;
    titleMobile: React.CSSProperties;
    container: React.CSSProperties;
    containerMobile: React.CSSProperties;
    cardTitle: React.CSSProperties;
    cardsList: React.CSSProperties;
    cardsListMobile: React.CSSProperties;
    label: React.CSSProperties;
    cardContent: React.CSSProperties; 
    services: React.CSSProperties; 

}

const styles: Styles = {
    title: {
        fontSize: '16px',
        fontWeight: 'bold'
    },
    titleMobile: {
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        gap: '1rem',
        padding: '18px 12px',
        margin: '8px 0',
        border: '.1rem solid #DFDCDC',
        borderRadius: '12px',
        width: '14rem'
    },
    containerMobile: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
        padding: '18px 12px',
        margin: '8px 0',
        border: '.1rem solid #DFDCDC',
        borderRadius: '12px',
        width: '100%'
    },
    cardTitle:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
    },
    cardsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
        margin: '2rem 0'
    },
    cardsListMobile: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        gap: '1rem',
        margin: '2rem 0'
    },
    label:{
        fontSize: '0.9rem'
    },
    cardContent:{
        border: '1px solid #DFDCDC',
        borderWidth: '1px 0 0 0',
        paddingTop: '8px'
    },
    services: {
        fontSize: '13px'
    }

}

const UserCarrierCard: FC<props> = ({ carrier }) => {
    const { isTablet } = useDevice()

    return (
        <article style={isTablet ? styles.containerMobile : styles.container}>
            <section style={styles.cardTitle}>
                <div>
                    <img width={58} src={assingDashboardLogos(carrier.name)} alt={carrier.name} />
                </div>
                <div>
                    <Tag color={carrier.active ? 'cyan' : 'red'}>{carrier.active ? "Activada" : "Inactiva"}</Tag>
                </div>
            </section>
            <section style={styles.cardContent}>
                <Typography style={styles.label}><strong>{carrierLabels(carrier.name)}</strong></Typography>
                <Typography style={styles.label}>{carrier.label_format || 'PDF 4x6'}</Typography>
                {
                    carrier.services.length === 0 ? (
                        <Typography style={styles.label}>Todos los servicios activos</Typography>
                    ) : carrier.services.map(item => (
                        <span style={styles.services}>{item.label.es}, </span>
                    ))
                }
            </section>
        </article>
    )
}

export default UserCarrierCard
