import FedexLogo from "../../../assets/images/ParcelDashboard/fedex.webp";
import AMPMLogo from "../../../assets/images/ParcelDashboard/ampm.webp";
import EstafetaLogo from "../../../assets/images/ParcelDashboard/estafeta.webp";
import DHLogo from "../../../assets/images/ParcelDashboard/dhl.webp";
import SendexLogo from "../../../assets/images/ParcelDashboard/sendex.webp";
import Noventa9Logo from "../../../assets/images/ParcelDashboard/noventa9minutos.webp";
import ivoyLogo from "../../../assets/images/ParcelDashboard/ivoy.png";
import BorzoLogo from "../../../assets/images/ParcelDashboard/borzo.png";
import JTexpress from "../../../assets/images/ParcelDashboard/jtexpress.webp";
import CarssaLogo from "../../../assets/images/ParcelDashboard/carssa.png";
import SCMLogo from "../../../assets/images/ParcelDashboard/scm.png";
import AExpressLogo from '../../../assets/images/ParcelDashboard/aexpress.png'
import entregaLogo from '../../../assets/images/ParcelDashboard/en-trega.webp'
// import RedpackLogo from "../../../assets/images/ParcelDashboard/redpack.png";
import PaquetexpressLogo from "../../../assets/images/ParcelDashboard/paquetexpress.webp";
import Quikenlogo from "../../../assets/images/ParcelDashboard/quiken.webp";
import Uberlogo from "../../../assets/images/ParcelDashboard/uber.png";
import TracusaLogo from "../../../assets/images/ParcelDashboard/tracusa.png";
import MensajerosULogo from "../../../assets/images/ParcelDashboard/mensajerosUrbanos.png";
import treggoLogo from "../../../assets/images/ParcelDashboard/treggo.png";
import upsLogo from "../../../assets/images/ParcelDashboard/ups.webp";
import RedpackLogo from "../../../assets/images/ParcelDashboard/redpack.png";
import interrapidisimoLogo from "../../../assets/images/ParcelDashboard/interrapidisimo.png";
import tresguerrasLogo from "../../../assets/images/ParcelDashboard/tresguerras.png";
import moovaLogo from "../../../assets/images/ParcelDashboard/moova.png";
import pickitLogo from "../../../assets/images/ParcelDashboard/pickit.png";
import deprisaLogo from "../../../assets/images/ParcelDashboard/deprisa.png";
import coordinadoraLogo from "../../../assets/images/ParcelDashboard/coordinadora.png";
import servientregaLogo from "../../../assets/images/ParcelDashboard/servientrega.png";
import tccLogo from "../../../assets/images/ParcelDashboard/tcc.webp";
import enviacoLogo from "../../../assets/images/ParcelDashboard/enviaco.webp";
import imileLogo from "../../../assets/images/ParcelDashboard/imile.webp";
import vencedorLogo from "../../../assets/images/ParcelDashboard/vencedor.webp";
import redserviLogo from "../../../assets/images/ParcelDashboard/redServi.webp";

import MXIcon from "../../../assets/images/Countries/MX.png";
import COIcon from "../../../assets/images/Countries/CO.png";
import PEIcon from "../../../assets/images/Countries/PE.svg";
import WorldIcon from "../../../assets/images/CountriesFlags/world.svg";

import AuthLogo from "../../../assets/images/auth0-logo.png";
import Googlelogo from "../../../assets/images/google-logo.png";
import Applelogo from "../../../assets/images/apple-logo.png";
import FbLogo from "../../../assets/images/facebook-logo.png";

import { UserItemI } from "./Search/SearchItem";
import { CriteriaType, DataTypeT, userActionsT } from "./interfaces";
import { dataToShowI } from "../UsersBeta/interfaces";

export const isNegativeNumber = (number: number) => {
  return Math.sign(number) === -1 ? true : false;
};

export const getAvatarName = (actualUser: any) => {
  return actualUser?.name
    ? actualUser?.name?.charAt(0).toUpperCase()
    : actualUser?.email?.charAt(0).toUpperCase();
};

export const switchCountry = (country: string) => {
  switch (country) {
    case "CO":
      return COIcon;
    case "PE":
      return PEIcon;
    case "MX":
      return MXIcon;
    default:
      return WorldIcon;
  }
};

export const countryName = (country: string) => {
  switch (country) {
    case "CO":
      return "Colombia";
    case "PE":
      return "Perú";
    case "MX":
      return "México";
    default:
      return "PENDIENTE";
  }
};

export const currencyName = (country: string) => {
  switch (country) {
    case "CO":
      return "COP";
    case "PE":
      return "PER";
    case "MX":
      return "MXN";
    default:
      return "N/A";
  }
};

export const regMethodImg = (user: string) => {
  const method = user.split("|")[0];

  switch (method) {
    case "facebook":
      return {
        logo: FbLogo,
        height: 20,
      };
    case "google-oauth2":
      return {
        logo: Googlelogo,
        height: 25,
      };
    case "apple":
      return {
        logo: Applelogo,
        height: 40,
      };

    default:
      return {
        logo: AuthLogo,
        height: 25,
      };
  }
};

export const dataTypeModalTitle = (type: DataTypeT) => {
  switch (type) {
    case "shipments":
      return "Envíos";
    case "movements":
      return "Movimientos";
    default:
      return "Otros";
  }
};

export const balanceFixed = (user: UserItemI) => {
  const balanceToCut = user?.balance || 0;

  return Math.trunc(balanceToCut * 100) / 100;
};

export const criteriaSearchPlaceholder = (type: CriteriaType) => {
  switch (type) {
    case "email":
      return "Escriba un correo electrónico";
    case "user_id":
      return "Escriba un ID de usuario";
    case "phone":
      return "Escriba un teléfono asociado con una cuenta";
    case "balance":
      return "Escriba una cantidad de saldo asociado a una cuenta";
    case "tracking":
      return "Escriba un número de guía";
    case "movement":
      return "Escriba un folio de rastreo de una recarga";
    case "stp":
      return "Escriba una clabe STP vinculada con un usuario";
    default:
      return "Escriba un texto o correo electrónico";
  }
};

export const userActionsModalTexts = (type: userActionsT) => {
  switch (type) {
    case "block":
      return {
        title: "Bloquear usuario",
        alert:
          "El usuario ya no podrá ingresar a la plataforma ni recargar saldo ni generar envíos.",
      };
    case "unblock":
      return {
        title: "Desbloquear usuario",
        alert: "El usuario volverá a tener acceso a la plataforma.",
      };
    case "recharge":
      return {
        title: "Recargar saldo",
        placeholders: {
          amount: "Introduzca la cantidad a recargar",
          description: "Introduce una descripción de la recarga",
        },
        alert:
          "Esta acción no se puede deshacer, confirme que sea el usuario correcto.",
      };
    case "discount":
      return {
        title: "Descontar saldo",
        placeholders: {
          amount: "Introduzca la cantidad a descontar",
          description: "Introduce una descripción del descuento de saldo",
        },
        alert:
          "Esta acción no se puede deshacer, confirme que sea el usuario correcto.",
      };
    case "update":
      return {
        title: "Actualizar usuario",
        alert: "Actualice los datos del usuario.",
      };
    default:
      return {
        title: "Actualizar usuario",
        alert: "Actualice los datos del usuario.",
      };
  }
};

export const getLoginAvatarColor = (user_id: string) => {
  const provider = user_id?.split("|")[0];
  switch (provider) {
    case "google-oauth2":
      return "#F75F5F";
    case "apple":
      return "#32424D";
    case "facebook":
      return "#3298E3";
    default:
      return "#149DBB";
  }
};

export const dataToShowDefault: dataToShowI = {
  user: "",
  name: "",
  email: "",
  roles: ["common"],
  registerType: "",
  country: "",
  phone: "",
  balance: 0,
  senders: [],
  registerDate: "",
  howUFindUs: "",
  verified: false,
  internationalShipping: false,
  sendersValidation: false,
  admin: false,
  security: {
    international_senders: false,
    identity_verified_by_document: false,
    isBlocked: false,
    senders: [],
    identity_verified: false,
    senders_verified: false,
    vip: false,
    special_accounts: false,
    estafeta_special: false,
  },
  carriers: [],
  manage_carriers: false,
  metadata: {
    activity: {
      completedRegister: null,
      completedProfile: null,
      OTP: null,
      firstRechange: null,
      lastRechange: null,
    },
    stp_account: "",
  },
  company: "",
  reg_method: "",
  recommended: "",
  app_country: "",
  created_at: null,
  shipments_count: 0,
  movements_count: 0,
  last_shipment: ''
};

export const modalDataDefault = {
  requestDataAdd: {
    balance: { amount: 0, currency: "", monitored: false },
    app_country: "",
    comprobanteId: "",
  },
  requestDataRemove: {
    balance: { amount: 0, currency: "" },
    app_country: "",
  },
  inputModal: {
    nameToSend: "",
    emailToSend: "",
    phoneToSend: "",
  },
};

export const assingDashboardLogos = (carrier: string) => {
  switch (carrier) {
    case "fedex":
      return FedexLogo;

    case "estafeta":
      return EstafetaLogo;

    case "sendex":
      return SendexLogo;

    case "dhl":
      return DHLogo;

    case "noventa9Minutos":
      return Noventa9Logo;

    case "borzo":
      return BorzoLogo;

    case "ampm":
      return AMPMLogo;

    case "jtexpress":
      return JTexpress;

    case "ivoy":
      return ivoyLogo;

    case "scm":
      return SCMLogo;

    case "carssa":
      return CarssaLogo;

    case "paquetexpress":
      return PaquetexpressLogo;

    case "quiken":
      return Quikenlogo;

    case "uber":
      return Uberlogo;

    case "tracusa":
      return TracusaLogo;

    case "mensajerosUrbanos":
      return MensajerosULogo;

    case "treggo":
      return treggoLogo;

    case "ups":
      return upsLogo;

    case "redpack":
      return RedpackLogo;

    case "interrapidisimo":
      return interrapidisimoLogo;

    case "tresguerras":
      return tresguerrasLogo;

    case "aExpress":
      return AExpressLogo;
      
    case "vencedor":
      return vencedorLogo;
      
    case "entrega":
      return entregaLogo;

    case "moova":
      return moovaLogo;

    case "pickit":
      return pickitLogo;

    case "deprisa":
      return deprisaLogo;

    case "coordinadora":
      return coordinadoraLogo;

    case "redServi":
      return redserviLogo;

    case "serviEntrega":
      return servientregaLogo;

      case "tcc":
        return tccLogo;
  
      case "envia":
        return enviacoLogo;
      
      case "imile":
        return imileLogo;
  }
  console.log("entraaaaaa", carrier)
  return FedexLogo;
};
