// Libraries
import i18n from "../languages/langs";

// Icons
import {
  DollarOutlined,
  IdcardOutlined,
  DownloadOutlined,
  UserOutlined,
  FileTextOutlined,
  FundOutlined,
  TeamOutlined,
  HomeOutlined,
  CodeOutlined,
  StockOutlined,
  // PieChartOutlined,
  BulbOutlined,
  ContainerOutlined, CalendarOutlined,
} from "@ant-design/icons";

// Page Sections
// import Users from "../pages/DashboardSections/Users";
import Permissions from "../pages/DashboardSections/Permissions";
import { Prices } from "../pages/PricesSection/Home";
import Descargas from "../pages/DashboardSections/Data/Data";
import Records from "../pages/DashboardSections/Records";
import Data2 from "../pages/DataSection";
import Desarrolladores from "../pages/DevsSection";
// import Statistics from "../pages/MarketingSection/Statistics";
import DashboardAdmin from "../pages/Admin/Dashboard";
import ErrorsAnalitycs from "../pages/LogsAnalitycsSection/ErrorAnalitycs";
import {
  AppsOutlined,
  CodeRounded,
  GraphicEqOutlined,
  NotificationsOutlined,
  // GraphicEqOutlined
} from "@material-ui/icons";
import DevicesAnalytics from "../pages/LogsAnalitycsSection/DevicesAnalytics";
import Partners from "../pages/DashboardSections/Data/Partners/Partners";
import Fulfillment from "../pages/DashboardSections/Data/Fulfillment/Fulfillment";
import SellSection from "../pages/Sells";
import { Sms } from "../pages/Sms/Sms";
import UsersBeta from "../pages/DashboardSections/UsersBeta";
import TrackingSection from "../pages/MarketingSection/TrackingSection";
import TrackingCustomerTabs from "../pages/Sells/trackingCustomerTabs";

const UserMenu: SectionsObject = {
  Inicio: {
    title: "Inicio",
    header: i18n.t("Home.Title"),
    description: i18n.t("Home.Subtitle"),
    identifier: "Inicio",
    icon: <HomeOutlined />,
    component: <DashboardAdmin />,
  },
  /*
  Usuarios: {
    title: i18n.t("Users.Nav"),
    header: i18n.t("Users.Title"),
    description: i18n.t("Users.Subtitle"),
    identifier: "Usuarios",
    icon: <UserOutlined />,
    component: <Users />,
  },
  */
  Usuarios: {
    title: i18n.t("Users.Nav"),
    header: i18n.t("Users.Title"),
    description: i18n.t("Users.Subtitle"),
    identifier: "Usuarios",
    icon: <UserOutlined />,
    component: <UsersBeta />,
  },
  Secciones: {
    title: i18n.t("Records.Nav"),
    header: i18n.t("Records.Title"),
    description: i18n.t("Records.Subtitle"),
    identifier: "Secciones",
    icon: <FileTextOutlined />,
    component: <Records />,
  },
  Marketing: {
    title: i18n.t("Marketing.Nav"),
    header: i18n.t("Marketing.Title"),
    description: i18n.t("Marketing.Subtitle"),
    identifier: "Marketing",
    icon: <BulbOutlined />,
    type: "subMenu",
    children: [
      // {
      //   title: i18n.t("Marketing.Statistics.Nav"),
      //   identifier: "Estadisticas",
      //   icon: <PieChartOutlined />,
      // },
      {
        title: i18n.t("Marketing.Tracing.Nav"),
        identifier: "Seguimientos",
        icon: <StockOutlined />,
      },
      {
        title: "Mensajes SMS",
        identifier: "Sms",
        icon: <NotificationsOutlined />,
      },
    ],
  },
  Registros: {
    title: i18n.t("DataSection.Nav"),
    header: i18n.t("DataSection.Title"),
    description: i18n.t("DataSection.Subtitle"),
    identifier: "Registros",
    icon: <FundOutlined />,
    component: <Data2 />,
  },
  Referidos: {
    title: i18n.t("Referidos"),
    header: i18n.t("Referidos"),
    description: i18n.t("Gestiona tus referidos"),
    identifier: "Referidos",
    icon: <TeamOutlined />,
    component: <Partners />,
  },
  Fulfillment: {
    title: i18n.t("Fulfillment"),
    header: i18n.t("Fulfillment"),
    description: i18n.t("Gestiona tu actividad en fulfillment"),
    identifier: "Fulfillment",
    icon: <ContainerOutlined />,
    component: <Fulfillment />,
  },
  Precios: {
    title: i18n.t("Prices.Nav"),
    header: i18n.t("Prices.Title"),
    description: i18n.t("Prices.Subtitle"),
    identifier: "Precios",
    icon: <DollarOutlined />,
    component: <Prices />,
  },
  // Estadisticas: {
  //   title: i18n.t("Marketing.Statistics.Nav"),
  //   header: i18n.t("Marketing.Statistics.Title"),
  //   description: i18n.t("Marketing.Statistics.Subtitle"),
  //   identifier: "Estadisticas",
  //   icon: <FundOutlined />,
  //   component: <Statistics />,
  //   type: "subMenuItem",
  // },
  Seguimientos: {
    title: i18n.t("Marketing.Tracing.Nav"),
    header: i18n.t("Marketing.Tracing.Title"),
    description: i18n.t("Marketing.Tracing.Subtitle"),
    identifier: "Seguimientos",
    icon: <FundOutlined />,
    component: <TrackingSection />,
    type: "subMenuItem",
  },
  Sms: {
    title: "Notidficaciones SMS",
    header: "Notificaciones SMS",
    description: "Ve el historial de los mensajes enviados",
    identifier: "Sms",
    icon: <NotificationsOutlined />,
    component: <Sms />,
    type: "subMenuItem",
  },
  Ventas: {
    title: "Ventas",
    header: "Ventas",
    description: "Estrategias de venta",
    identifier: "Ventas",
    icon: <GraphicEqOutlined />,
    type: "subMenu",
    children: [
      {
        title: "Adm. Vendedores",
        identifier: "Vendedores",
        icon: <HomeOutlined />
      },
      {
        title: "Seg. de clientes",
        identifier: "Seguimiento",
        icon: <CalendarOutlined />
      }
    ]
  },
  Vendedores: {
    title: "Vendedores",
    header: "Ventas",
    description: "Asignar y verificar clientes y vendedores",
    identifier: "Vendedores",
    icon: <HomeOutlined />,
    component: <SellSection />,
    type: "subMenuItem"
  },
  Seguimiento: {
    title: "Seguimiento",
    header: "Seguimiento",
    description: "Historial de contacto de vendedores con clientes",
    identifier: "Seguimiento",
    icon: <HomeOutlined />,
    component: <TrackingCustomerTabs />,
    type: "subMenuItem"
  },
  Descargas: {
    title: i18n.t("Downloads.Nav"),
    header: i18n.t("Downloads.Title"),
    description: i18n.t("Downloads.Subtitle"),
    identifier: "Descargas",
    icon: <DownloadOutlined />,
    component: <Descargas />,
  },
  Permisos: {
    title: i18n.t("Permissions.Nav"),
    header: i18n.t("Permissions.Title"),
    description: i18n.t("Permissions.Subtitle"),
    identifier: "Permisos",
    icon: <IdcardOutlined />,
    component: <Permissions />,
  },
  Developers: {
    title: i18n.t("Developers.Nav"),
    header: i18n.t("Developers.Title"),
    description: i18n.t("Developers.Subtitle"),
    identifier: "Desarrolladores",
    icon: <CodeOutlined />,
    type: "subMenu",
    children: [
      {
        title: "Inicio",
        identifier: "Desarrolladores",
        icon: <CodeRounded />,
      },
      {
        title: "Errores",
        identifier: "Errores",
        icon: <FundOutlined />,
      },
      {
        title: "Dispositivos",
        identifier: "Dispositivos",
        icon: <AppsOutlined />,
      },
    ],
  },
  Desarrolladores: {
    title: i18n.t("Developers.Nav"),
    header: i18n.t("Developers.Title"),
    description: i18n.t("Developers.Subtitle"),
    identifier: "Desarrolladores",
    icon: <CodeOutlined />,
    component: <Desarrolladores />,
    type: "subMenuItem",
  },
  Errores: {
    title: "Errores",
    header: "Estadisticas de errores",
    description: "Seguimiento y análisis",
    identifier: "Errores",
    icon: <FundOutlined />,
    component: <ErrorsAnalitycs />,
    type: "subMenuItem",
  },
  Dispositivos: {
    title: "Dispositivos",
    header: "Estadisticas de Dispositivos",
    description: "Seguimiento y análisis",
    identifier: "Dispositivos",
    icon: <AppsOutlined />,
    component: <DevicesAnalytics />,
    type: "subMenuItem",
  },
};

const UpdateData = () => {
  UserMenu.Inicio.title = i18n.t("Home.Nav");
  UserMenu.Inicio.header = i18n.t("Home.Title");
  UserMenu.Inicio.description = i18n.t("Home.Subtitle");

  UserMenu.Usuarios.title = i18n.t("Users.Nav");
  UserMenu.Usuarios.header = i18n.t("Users.Title");
  UserMenu.Usuarios.description = i18n.t("users.Subtitle");

  UserMenu.Secciones.title = i18n.t("Records.Nav");
  UserMenu.Secciones.header = i18n.t("Records.Title");
  UserMenu.Secciones.description = i18n.t("Records.Subtitle");

  UserMenu.Referidos.title = i18n.t("Referrals.Nav");
  UserMenu.Referidos.header = i18n.t("Referrals.Title");
  UserMenu.Referidos.description = i18n.t("Referrals.Subtitle");

  UserMenu.Fulfillment.title = i18n.t("Fulfillment.Nav");
  UserMenu.Fulfillment.header = i18n.t("Fulfillment.Title");
  UserMenu.Fulfillment.description = i18n.t("Fulfillment.Subtitle");

  UserMenu.Registros.title = i18n.t("DataSection.Nav");
  UserMenu.Registros.header = i18n.t("DataSection.Title");
  UserMenu.Registros.description = i18n.t("DataSection.Subtitle");

  UserMenu.Precios.title = i18n.t("Prices.Nav");
  UserMenu.Precios.header = i18n.t("Prices.Title");
  UserMenu.Precios.description = i18n.t("Prices.Subtitle");

  UserMenu.Marketing.title = i18n.t("Marketing.Nav");
  UserMenu.Marketing.header = i18n.t("Marketing.Title");
  UserMenu.Marketing.description = i18n.t("Marketing.Subtitle");

  // UserMenu.Estadisticas.title = i18n.t("Marketing.Statistics.Nav");
  // UserMenu.Estadisticas.header = i18n.t("Marketing.Statistics.Title");
  // UserMenu.Estadisticas.description = i18n.t("Marketing.Statistics.Subtitle");

  UserMenu.Ventas.title = i18n.t("Ventas.Nav");
  UserMenu.Ventas.header = i18n.t("Ventas.Title");
  UserMenu.Ventas.description = i18n.t("Ventas.Subtitle");

  UserMenu.Seguimientos.title = i18n.t("Marketing.Tracing.Nav");
  UserMenu.Seguimientos.header = i18n.t("Marketing.Tracing.Title");
  UserMenu.Seguimientos.description = i18n.t("Marketing.Tracing.Subtitle");

  UserMenu.Descargas.title = i18n.t("Downloads.Nav");
  UserMenu.Descargas.header = i18n.t("Downloads.Title");
  UserMenu.Descargas.description = i18n.t("Downloads.Subtitle");

  UserMenu.Permisos.title = i18n.t("Permissions.Nav");
  UserMenu.Permisos.header = i18n.t("Permissions.Title");
  UserMenu.Permisos.description = i18n.t("Permissions.Subtitle");

  UserMenu.Desarrolladores.title = i18n.t("Developers.Nav");
  UserMenu.Desarrolladores.header = i18n.t("Developers.Title");
  UserMenu.Desarrolladores.description = i18n.t("Developers.Subtitle");
};
i18n.on("languageChanged", () => UpdateData());

export { UserMenu };
