import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { ColumnsType } from 'antd/lib/table'
import { Button } from 'antd'
import { TableV3 } from '../../../../components/Common/TableV3'
import { AllColumnsType } from '../../../../interfaces/partner.interface'
import { useToggle } from '../../../../hooks/useToggle'
import { useTranslation } from 'react-i18next'
import { referralColumn } from './schemaColumns'
import { referralDownloads } from './schemaDownloads'
import { ApiContext } from '../../../../context/apiContext'
import { AppContext } from '../../../../context/appContext'
import { AdminDataContext } from '../../../../context/adminContext'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../../../interfaces/table.interface'

const backendUrlPartner = process.env.REACT_APP_PARTNERS_BACKEND_URL

const filterByColumnsFactory = ({
	country,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
	country,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
    { id: 'createdAt', find: false },
    { id: 'owner', find: false },
    { id: 'nickName', find: false },
    { id: 'email', find: false },
    { id: 'referralStatus', find: false }
  ]
})

const Referrals = () => {
  const { t } = useTranslation()
  const { userData } = useContext(AppContext)
  const { userToken } = useContext(ApiContext);
  const { country } = useContext(AdminDataContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
	const [initRender, setInitRender] = useState(false)

  const { state: newReferralModal, toggle: toggleNewReferralModal } = useToggle()
  const [activateNewReferral, setActivateNewReferral] = useState(false)
  const [activateDeleteReferral, setActivateDeleteReferral] = useState(false)

  const addNewReferral = async (newReferral: any) => {
    try {
      const rta = await axios.post(
        `${backendUrlPartner}/admin/referrals/creationManual`,
        newReferral, 
        { headers: { Authorization: `Bearer ${userToken}` }}
      )    
      
      return rta  
    } catch (e: any){
      return e.message
    }
  }

  const deleteReferral = async (id: string) => {
    try {
      const { data } = await axios.post(
        `${backendUrlPartner}/admin/referrals/delete`,
        { id }, 
        { headers: { Authorization: `Bearer ${userToken}` }}
      )    
      
      return data 
    } catch (e: any){
      return {
        code: 500,
        error: { message: e.message },
        res: null 
      }
    }
  }

  const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
    const { data } = await axios.post(
      `${backendUrlPartner}/admin/referrals/table`, 
      filterByColumns, 
      { headers: { Authorization: `Bearer ${userToken}` }}
    )

    setTotal(data?.total ?? 0)
    if (saveState){
      setDataColumns(data?.data ?? [])
    } else {
      return data?.data ?? []
    }
  }

  const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

  useEffect(() => {
		if (!initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
				setInitRender(true)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByColumns]) 
	
  useEffect(() => {
    if (initRender){
      (async () => {
        setCurrentPage(1)
        const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
        setFilterByColumns(customFilters)
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activateNewReferral, activateDeleteReferral])

  // useEffect(() => {
  //   if (initRender){
  //     (async () => {
  //       setCurrentPage(1)
  //       const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
  //       setFilterByColumns(customFilters)
  //     })();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [country])

  return (
    <div>
      {' '}
      <TableV3
        title={t('Referrals.Table.Title')}
        tableType="referrals"
        data={dataColumns ?? []}
        total={total}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        optionalContent={{
          search: (
            <>
              {userData?.metadata?.permissions?.referrals?.actions?.find((item: string) => item === 'assign_referrals') && (
                <Button className='add-new-referral' onClick={()=> toggleNewReferralModal()}>
                  {t("Referrals.Table.Button.AddReferral")}
                </Button>
              )}
            </>
          )
        }}
        schemaDownload={referralDownloads}
        columns={
            referralColumn(
              dataColumns ?? [],
              deleteReferral,
              activateDeleteReferral,
              setActivateDeleteReferral
            ) as ColumnsType<AllColumnsType>
        }
        stateValues={{
          country,
          currentPage,
          rowsPerPages,
          searchText,
          filterByColumns,
          newReferralModal
        }}
        filters={{
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredQuery) => {
            return await handleFecth(filter, false)
          },
          handleStateFilterByColumns: (props: filterByColumnsContructor) => {
						return handleStateFilterByColumns(props)
					},
					filterByColumnsFactory: (props: filterByColumnsContructor)=> {
						return filterByColumnsFactory(props)
					},
          manageReferrals: {
            activateNewReferral: () => {
                setActivateNewReferral(!activateNewReferral)
            },
            addNewReferral: async (newReferral: any) => {
                return await addNewReferral(newReferral)
            },
            toggleNewReferralModal(){
                toggleNewReferralModal()
            }
          }
        }}
      />
    </div>
  )
}

export default Referrals