import { FC, useEffect, useState } from "react";
import { Alert, Button, Form, Input, Row, Spin, notification } from "antd";
import ModalContainer, { modalSizes } from "../../../../../components/Containers/ModalContainer"
import { userActionsT } from "../../interfaces";
import { userActionsModalTexts } from "../../utils";
import { UserItemI } from '../../Search/SearchItem';
import { moneyISO } from "../../../../../constants/moneyISO";
import MaintenancePage from "../../Sections/MaintenancePage";
import { usePatch } from "../../../../../hooks/usePatch";
import { addBalance, blockUserV2, removeBalance, unBlockUserV2, updateUserV2 } from "../../../../../services/userbalance_d";
import TextArea from "antd/lib/input/TextArea";
import InputContainer from "../../../../../components/Containers/InputContainer";
import { UserBlacklistTypes } from "../../../../../components/Common/UserBlacklistStatus";

interface props {
    type: userActionsT;
    visible: boolean;
    userFound: UserItemI;
    manageModal: () => void;
    getFullUser: (user: string) => void;
    newUserFound: (user: UserItemI, update?: boolean) => void;
}

interface Styles {
    container: React.CSSProperties;
    mainSection: React.CSSProperties;
    input: React.CSSProperties;
    form: React.CSSProperties;
    inputContainer: React.CSSProperties;
    buttonInfo: React.CSSProperties;
    buttonError: React.CSSProperties;

}

const styles: Styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '2rem',
        width: '100%',
    },
    mainSection: {
        width: '100%'
    },
    input: {
        height: "2rem",
        marginBottom: "1rem"
    },
    form: {
        marginTop: '2rem'
    },
    inputContainer: {
        marginBottom: '8px'
    },
    buttonInfo: {
        backgroundColor: "#6D71F6",
        borderRadius: 16,
        width: "100%",
        marginBottom: 10,
        color: '#fff'
    },
    buttonError:
    {
        backgroundColor: "#F5222D",
        borderRadius: 16,
        width: "100%",
        marginBottom: 10,
        color: '#fff'
    }

}


const UserActionsModal: FC<props> = ({ visible, userFound, type, manageModal, getFullUser, newUserFound }) => {
    const [amount, setAmount] = useState(0);
    const [description, setDescription] = useState('');
    const [blockValues, setBlockValues] = useState({
        ban_reason: '',
        type: ''
    })
    const { fetchDataPatch: fetchBlock, isLoadingPatch: loadingBlock } = usePatch(blockUserV2)
    const { fetchDataPatch: fetchUnblock, isLoadingPatch: loadingUnblock } = usePatch(unBlockUserV2)
    const { fetchDataPatch: fetchAddBalance, isLoadingPatch: loadingAdd } = usePatch(addBalance);
    const { fetchDataPatch: fetchRemoveBalance, isLoadingPatch: loadingRemove } = usePatch(removeBalance);
    const { fetchDataPatch: fetchUpdate, isLoadingPatch: loadingUpdate } = usePatch(updateUserV2);
    const [form] = Form.useForm();

    useEffect(() =>{
        form.setFieldsValue({
            name: userFound.name,
            email: userFound.email,
            phone: userFound.phone,
            country: userFound.app_country
        })
    },[userFound]) //eslint-disable-line

    const onchangeAmount = (e: any) => {
        setAmount(e.target.value)
    }

    const onchangeDescription = (e: any) => {
        setDescription(e.target.value)
    }

    const onChangeType = (e: any) => {
        setBlockValues({
            ...blockValues,
            type: e
        })
    }

    const onChangeBanReason = (e: any) => {
        setBlockValues({
            ...blockValues,
            ban_reason: e.target.value
        })
    }


    const onSubmitRechargeAmount = async () => {
        try {
            await fetchAddBalance(userFound.user, {
                balance: {
                    amount,
                    currency: moneyISO[userFound.app_country],
                    monitored: false
                },
                description,
                app_country: userFound.app_country,
                comprobanteId: ""
            })

            notification.success({ message: "La solicitud se ha realizado correctamente" });
            manageModal()
            setAmount(0)
            getFullUser(userFound.user)
            newUserFound({
                ...userFound,
                balance: parseFloat((Number(userFound.balance + amount)).toFixed(2)),
            })


        } catch (error) {
            console.log(error)
            notification.error({ message: "Ha ocurrido un error, contacte al chat" });
        }
    }

    const onSubmitDiscountAmount = async () => {
        try {
            await fetchRemoveBalance(userFound.user, {
                balance: {
                    amount,
                    currency: moneyISO[userFound.app_country],
                    monitored: false
                },
                description,
                app_country: userFound.app_country
            })

            notification.success({ message: "La solicitud se ha realizado correctamente" });
            manageModal()
            setAmount(0)
            getFullUser(userFound.user)
            newUserFound({
                ...userFound,
                balance: parseFloat((Number(userFound.balance - amount)).toFixed(2)),
            })


        } catch (error) {
            console.log(error)
            notification.error({ message: "Ha ocurrido un error, contacte al chat" });
        }
    }

    const onSubmitBlockUser = async () => {
        try {
            const resp = await fetchBlock(userFound.user, {
                ban_reason: blockValues.ban_reason,
                type: blockValues.type as UserBlacklistTypes
            })

            if (resp) {
                notification.success({ message: "El usuario se ha bloqueado." });
                manageModal()
                setBlockValues({
                    ban_reason: '',
                    type: ''
                })
                getFullUser(userFound.user)
                newUserFound({
                    ...userFound,
                    security: {
                        ...userFound.security,
                        isBlocked: true
                    }
                }, true)
            } else {
                notification.error({ message: "Ha ocurrido un error, contacte al chat" });
            }

        } catch (error) {
            console.log(error)
            notification.error({ message: "Ha ocurrido un error, contacte al chat" });
        }
    }


    const onSubmitUnblockUser = async () => {
        try {
            const resp = await fetchUnblock(userFound.user, {})

            if (resp) {
                notification.success({ message: "El usuario se ha desbloqueado." });
                manageModal()
                getFullUser(userFound.user)
                newUserFound({
                    ...userFound,
                    security: {
                        ...userFound.security,
                        isBlocked: false
                    }
                }, true)
            } else {
                notification.error({ message: "Ha ocurrido un error, contacte al chat" });
            }

        } catch (error) {
            console.log(error)
            notification.error({ message: "Ha ocurrido un error, contacte al chat" });
        }
    }

    const onSubmitUpdate = async (values: any) => {
        try{
            const resp = await fetchUpdate(userFound.user, values)

            if (resp) {
                notification.success({ message: "El usuario se ha actualizado." });
                manageModal()
                getFullUser(userFound.user)
                newUserFound({
                    ...userFound,
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    app_country: values.country
                }, true)
            } else {
                notification.error({ message: "Ha ocurrido un error, contacte al chat" });
            }
            
        }catch(error){
            console.log(error)
            notification.error({ message: "Ha ocurrido un error, contacte al chat" });
        }
    }

    return (
        <ModalContainer
            title={userActionsModalTexts(type).title}
            visible={visible}
            onCancel={manageModal}
            width={modalSizes.BIG}
            footer={<></>}
        >
            <Row style={styles.container}>
                <section style={styles.mainSection}>
                    <Alert
                        message={userActionsModalTexts(type).alert}
                        type={type === "update" || type === "recharge" ? "info" : "error"}
                    />
                    <section style={styles.form}>
                        <Spin spinning={loadingAdd || loadingRemove || loadingBlock || loadingUnblock || loadingUpdate} >
                            {
                                type === 'recharge' || type === 'discount' ? (
                                    <>
                                        <div style={styles.inputContainer}>
                                            <Input
                                                addonBefore={moneyISO[userFound.app_country]}
                                                style={styles.input}
                                                placeholder={userActionsModalTexts(type).placeholders?.amount}
                                                onChange={onchangeAmount}
                                                type="number"
                                                value={amount}
                                            />
                                        </div>
                                        <div style={styles.inputContainer}>
                                            <Input
                                                style={styles.input}
                                                placeholder={userActionsModalTexts(type).placeholders?.description}
                                                onChange={onchangeDescription}
                                                type="text"
                                                value={description}
                                            />
                                        </div>
                                        <div>
                                            <Button
                                                style={type === 'recharge' ? styles.buttonInfo : styles.buttonError}
                                                htmlType="button"
                                                onClick={() => {
                                                    if (type === 'recharge') {
                                                        onSubmitRechargeAmount()
                                                    } else {
                                                        onSubmitDiscountAmount()
                                                    }
                                                }}
                                                disabled={!amount || !description}
                                            >
                                                {type === 'recharge' ? 'Recargar saldo' : 'Descontar saldo'}
                                            </Button>
                                        </div>
                                    </>

                                ) : type === 'block' || type === 'unblock' ? (
                                    <>
                                        {
                                            type === 'block' && (
                                                <>
                                                    <div style={styles.inputContainer}>
                                                        <InputContainer
                                                            placeholder="Tipo de bloqueo"
                                                            type="select"
                                                            valueContainerName="type"
                                                            required
                                                            optionsList={[
                                                                {
                                                                    value: UserBlacklistTypes.suspicious,
                                                                    label: "Sospecha"
                                                                },
                                                                {
                                                                    value: UserBlacklistTypes.manyShipments,
                                                                    label: "Múltipes Envíos"
                                                                },
                                                                {
                                                                    value: UserBlacklistTypes.reseller,
                                                                    label: "Revendedor"
                                                                },
                                                                {
                                                                    value: UserBlacklistTypes.overweight,
                                                                    label: "Sobrepesos"
                                                                },
                                                                {
                                                                    value: UserBlacklistTypes.multipleAccounts,
                                                                    label: "Multicuentas"
                                                                }
                                                            ]}
                                                            style={{ margin: 0 }}
                                                            onChange={(e) => onChangeType(e)}
                                                        />
                                                    </div>
                                                    <div style={styles.inputContainer}>
                                                        <Form.Item name='ban_reason'>
                                                            <TextArea
                                                                rows={6}
                                                                style={{ marginTop: '10px' }}
                                                                placeholder={'Motivo del bloqueo'}
                                                                onChange={(e) => onChangeBanReason(e)}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                </>
                                            )
                                        }

                                        <div>
                                            <Button
                                                style={styles.buttonError}
                                                htmlType="button"
                                                onClick={() => {
                                                    if (type === 'block') {
                                                        onSubmitBlockUser()
                                                    } else {
                                                        onSubmitUnblockUser()
                                                    }
                                                }}
                                                disabled={
                                                    type === 'block' ?
                                                    !blockValues.ban_reason || !blockValues.type :
                                                    false
                                                }
                                            >
                                                {type === 'block' ? 'Bloquear usuario' : 'Desbloquear usuario'}
                                            </Button>
                                        </div>
                                    </>
                                ) : type === 'update' ? (
                                    <Form form={form} onFinish={onSubmitUpdate}>
                                        <div style={styles.inputContainer}>
                                            <InputContainer
                                                placeholder="Nombre"
                                                type="text"
                                                valueContainerName="name"
                                                required
                                                style={{ margin: 0 }}
                                            />
                                        </div>
                                        <div style={styles.inputContainer}>
                                            <Form.Item
                                                name={"email"}
                                                rules={[
                                                    {
                                                        pattern: new RegExp(
                                                            "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
                                                        ),
                                                        message: "No coincide el patron de email",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </div>
                                        <div style={styles.inputContainer}>
                                            <InputContainer
                                                placeholder="Telefono"
                                                type="text"
                                                valueContainerName="phone"
                                                style={{ margin: 0 }}
                                            />
                                        </div>
                                        <div style={styles.inputContainer}>
                                            <InputContainer
                                                placeholder="País"
                                                type="select"
                                                valueContainerName="country"
                                                style={{ margin: 0 }}
                                                optionsList={[
                                                    {
                                                        value: "MX",
                                                        label: "MX"
                                                    },
                                                    {
                                                        value: "CO",
                                                        label: "CO"
                                                    },
                                                    {
                                                        value: "PE",
                                                        label: "PE"
                                                    }
                                                ]}
                                            />
                                        </div>
                                        <div>
                                            <InputContainer
                                                style={styles.buttonInfo}
                                                title="Actualizar usuarios"
                                                type="submitButton"
                                            />
                                        </div>
                                    </Form>
                                ) : < MaintenancePage />
                            } </Spin>
                    </section>

                </section>

            </Row>
        </ModalContainer>
    )
}

export default UserActionsModal