import { apiProps, apiResponse } from "../interfaces/app.interfaces";
// import moment from "moment";

export const DEFAULT_ADMIN_ROUTE = "admin";

export type AdminBodyRanges = {
	range1: Date;
	range2: Date;
};

export type AdminBodyRangesAndCountry = {
	ranges: AdminBodyRanges;
	country: String;
};

export type AdminBodyCountry = {
	country: String;
};

export const getAllShipmentsByRange =
	(ranges: AdminBodyRanges) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/shipments`,
			data: ranges,
		});
	};

export const getAllShipmentsByDateRange =
	(rangesAndCountry: AdminBodyRangesAndCountry) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/shipmentsByDateRange`,
			data: rangesAndCountry,
		});
	};

export const getAllShipmentsByMonths =
	(rangesAndCountry: AdminBodyRangesAndCountry) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/shipmentsByMonths`,
			data: rangesAndCountry,
		});
	};

export const getAllShipmentsByYears =
	(country: AdminBodyCountry) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/shipmentsByYears`,
			data: country,
		});
	};

// export const getCurrentShipmentsDay =
//   () => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
//     return await backendApiCall({
//       method: 'POST',
//       endpoint: `${DEFAULT_ADMIN_ROUTE}/shipments/current-day`,
//     })
//   }

export const getAveragePriceByRange =
	(ranges: AdminBodyRanges) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/shipments/average/price`,
			data: ranges,
		});
	};

export const getAverageWeightByRange =
	(ranges: AdminBodyRanges) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/shipments/average/weight`,
			data: ranges,
		});
	};

export const getTotalShipmentsByRange =
	(ranges: AdminBodyRanges) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/shipments/total`,
			data: ranges,
		});
	};

export const getShipmentsGroupedByStatusInRange =
	(ranges: AdminBodyRanges) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/shipments/status`,
			data: ranges,
		});
	};

export const updateUserData =
	(userId: String, dto: any) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "PUT",
			endpoint: `users/user/${userId}`,
			data: dto,
		});
	};

export const getAdminAnalytics =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments`,
			data: dto,
		});
	};

export const getAverageWeightAndCost =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments/averages`,
			data: dto,
		});
	};

export const getTotalShipmentsToday =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments/daily/total`,
			data: dto,
		});
	};
export const getShipmentsCurrentMonth =
	(country: string) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments/monthly/total/${country}`,
		});
	};

export const getMostUsedDestinations =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments/destinations/popular`,
			data: dto,
		});
	};

export const getMostUsedParcels =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/parcels/popular`,
			data: dto,
		});
	};

export const getMultipackageShipments =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments/multipackage`,
			data: dto,
		});
	};

export const getBlockedUsersDaily =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/daily/blacklist`,
			data: dto,
		});
	};

export const getRegisteredUsersDaily =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/users/daily/registered`,
			data: dto,
		});
	};

export const getLogsAnalytcs =
	(starDate: Date | String, endDate: Date | String) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `admin/errors-analitycs/${starDate}/${endDate}`,
		});
	};

export const getCompareMonths =
	() => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `admin/getCompareMonths`,
		});
	};

// export const getPickusAnalyticsLast =
//   () => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
//     return await backendApiCall({
//       method: "GET",
//       endpoint: `admin/getPickusAnalyticsLast`,
//     });
//   };

// export const getPickupsMonths =
//   () => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
//     return await backendApiCall({
//       method: "GET",
//       endpoint: `admin/getPickupsMonths`,
//     });
//   };

export const getOneController =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: "admin/metricbycomponet",
			data: dto,
		});
	};

export const getReport =
	(dto: { dateStart: Date | null; component: String }) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: "admin/getMetricError",
			data: dto,
		});
	};

export const getMetricError =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: "admin/getMostErrorComponent",
			data: dto,
		});
	};

export const getDrEnvioLogs = async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
	return await backendApiCall({
		method: "GET",
		endpoint: "admin/logs",
	});
};

export const getMovements =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/movements`,
			data: dto,
		});
	};

export const getUserTokens =
	(country: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/user/tokens/${country}`,
		});
	};

export const deleteApiClient =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/delete/apiclient`,
			data: dto,
		});
	};

export const updateApiClientAccess =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/update/apiclient`,
			data: dto,
		});
	};

export const customerTokenAdd =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/customer/tokens/add`,
			data: dto,
		});
	};

export const customerTokenUpdate =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/customer/tokens/update`,
			data: dto,
		});
	};

export const addNewFeature =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/addNewFeature`,
			data: dto,
		});
	};

export const getAllFeatures = async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
	return await backendApiCall({
		method: "GET",
		endpoint: `${DEFAULT_ADMIN_ROUTE}/getAllFeatures`,
	});
};

export const getImpactAnalitycs =
	(filter?: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/impact/analitycs`,
			data: filter,
		});
	};

export const getShipmentsPerMonth =
	(country: string, gte: string, lte: string) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/getShipmentsBySupplierForCurrentMonth`,
			data: {
				"origin.country": country,
				created_at: {
					$gte: gte,
					$lte: lte,
				},
			},
		});
	};

export const getShipmentsProviderPerMonth =
	(country: string, gte: string, lte: string) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments/provider`,
			data: {
				"origin.country": country,
				created_at: {
					$gte: gte,
					$lte: lte,
				},
			},
		});
	};

export const getShipmentsToday =
	(country: string) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/getShipmentsBySupplier/${country}`,
		});
	};

export const getInternationalShipmentsPerMonth =
	(rangesAndCountry: AdminBodyRangesAndCountry) =>
	async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/getInternationalShipmentsPerMonth`,
			data: rangesAndCountry,
		});
	};

export const getVerifyServices =
	() => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/verifyServices`,
		});
	};

export const getNewRecordsByDay =
	(filter?: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/getNewRecordsByDay`,
			data: { filter },
		});
	};

export const getIntegrationsByTypeAndMonth =
	(filter?: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/getIntegrationsByTypeAndMonth`,
			data: { filter },
		});
	};

export const getMostUsedPackagesByDay =
	(country: string) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "GET",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/parcels/popular/daily/${country}`,
		});
	};

export const quotesPerCarrier =
	(filter?: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/quotesPerCarrier`,
			data: { ...filter },
		});
	};

export const getShipmentsByType =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments/type`,
			data: dto,
		});
	};

export const getShipmentsByWeight =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/analytics/dashboard/shipments/weight`,
			data: dto,
		});
	};

export const getMostDestinations =
	(dto: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/getMostDestinations`,
			data: dto,
		});
	};

export const getUserTopShipments =
	(filter: any) => async (backendApiCall: (data: apiProps) => Promise<apiResponse>) => {
		return await backendApiCall({
			method: "POST",
			endpoint: `${DEFAULT_ADMIN_ROUTE}/getUserTopShipments`,
			data: filter,
		});
	};
