import { t } from 'i18next'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { ColumnsType } from 'antd/lib/table'
import { ApiContext } from '../../../../context/apiContext'
import { AdminDataContext } from '../../../../context/adminContext'
import { TableV3 } from '../../../../components/Common/TableV3'
import { FormatedShipmentForTables, ShipmentStatus, ShipmentV2 } from '../../../../interfaces/shipments.interface'
import { CancelStatus } from '../../../../components/Common/CancelStatus'
import { getParcelImage, linkRastreo } from '../../../../services/records'
import { UpdateStatusForm } from './UpdateStatusForm'
import { Actions as CancelationsActions } from './Actions'
import { filterByColumnsContructor, GetAllFilteredQuery } from '../../../../interfaces/table.interface'

interface CancellationsColumnsType {
  key: React.Key
  created_at: string
  profile: {
    email: string
  }
  shipment_status: string
  tracking: string
  carrier: string
  price: number
  service_id: string
}

const filterByColumnsFactory = ({
	country,
	currentPage = 1,
	rowsPerPages = 20, 
	searchText = ''
}: filterByColumnsContructor) => ({
	country,
	searched: searchText,
	range: { from: currentPage, to: rowsPerPages },
	columns: [
    { id: 'created_at', find: false },
    { id: 'user', find: false },
    { id: 'tracking', find: false },
    { id: 'carrier', find: false },
    { id: 'price', find: false },
    {
      id: 'cancellation_status',
      find: true,
      filters: {
        sort: false,
        filter: true,
        filter_values: ['Solicitada', 'Cancelada y usada', 'Saldo abonado', 'Cancelada de prov']
      }
    }
  ]
})

const CancellationsV2 = () => {
  const { t } = useTranslation()
  const { userToken, backendURL } = useContext(ApiContext)
  const { country } = useContext(AdminDataContext)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPages, setRowPerPages] = useState(20)
  const [total, setTotal] = useState(0)
  const [refetching, setIsRefetching] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [dataColumns, setDataColumns] = useState<any>(null)
  const [filterByColumns, setFilterByColumns] = useState<GetAllFilteredQuery>(filterByColumnsFactory({ country, currentPage, rowsPerPages, searchText }))
	const [initRender, setInitRender] = useState(false)

  const handleFecth = async (filterByColumns: GetAllFilteredQuery, saveState = true) => {
    const { data } = await axios.post(
        `${backendURL}shipments/getAllCancellationsV2`, 
        filterByColumns, 
        { headers: { Authorization: `Bearer ${userToken}` }}
    )
    
    setTotal(data?.total)
    data?.data?.forEach((item: any)=> { item.shipmentData = item })

    if (saveState) {
      setDataColumns(data?.data)
    } else {
      return data?.data ?? []
    }
  }

  const handleStateFilterByColumns = ({ user, searchText, country, currentPage = 1, rowsPerPages }: filterByColumnsContructor) => {	
		const customFilterByColumns = { 
		  	...filterByColumns,
		}
	
		if (user) customFilterByColumns.user = user	
		if (country) customFilterByColumns.country = country
		if (searchText !== undefined) customFilterByColumns.searched = searchText
		
		if (currentPage){
			setCurrentPage(currentPage)
			customFilterByColumns.range.from = currentPage 
		}
	
		if (rowsPerPages){
			setRowPerPages(rowsPerPages)
			customFilterByColumns.range.to = rowsPerPages 
		}
	
		return customFilterByColumns
	}

	useEffect(() => {
		if (!initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
				setInitRender(true)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (initRender){
			(async () => {
				setIsRefetching(true)
				await handleFecth(filterByColumns)
				setIsRefetching(false)
			})()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	  }, [filterByColumns]) 
	
  useEffect(() => {
    if (initRender){
      (async () => {
        setCurrentPage(1)
        const customFilters = filterByColumnsFactory({ country, currentPage: 1, rowsPerPages })
        setFilterByColumns(customFilters)
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country])

  return (
    <div>
      {' '}
      <TableV3
        title={t('Cancellations.Table.Title')}
        tableType="cancellations"
        isShipments
        data={dataColumns ?? []}
        total={total}
        isLoading={refetching}
        onChangePage={(page) => setCurrentPage(page)}
        onChangeRowsPerPage={(row) => setRowPerPages(row)}
        schemaDownload={CancellationsDownloads}
        columns={
            CancellationsColumnV2(
            async ()=> await handleFecth(filterByColumns),
            dataColumns ?? [],
            setDataColumns
          ) as ColumnsType<CancellationsColumnsType>
        }
        stateValues={{
          country,
          currentPage,
          rowsPerPages,
          searchText,
          filterByColumns,
        }}
        filters={{
          filterBySearchText(text: string) {
            setSearchText(text)
          },
          filterByColumns(filter: GetAllFilteredQuery) {
            setFilterByColumns(filter)
          },
          filterDownloads: async (filter: GetAllFilteredQuery) => {
            filter.isDownload = true
            return await handleFecth(filter, false)
          },
          handleStateFilterByColumns: (props: filterByColumnsContructor) => {
						return handleStateFilterByColumns(props)
					},
					filterByColumnsFactory: (props: filterByColumnsContructor)=> {
						return filterByColumnsFactory(props)
					}
        }}
      />
    </div>
  )
}

const CancellationsColumnV2 = (
  refetch: ()=> void,
  data: ShipmentV2[],
  setDataColumns: React.Dispatch<
  React.SetStateAction<FormatedShipmentForTables[] | null>
>
): ColumnsType<CancellationsColumnsType>=> {
  const uniqueCarriers = Array.from(new Set(data.map((item) => item.carrier)))
  const uniqueShipmentStatus = Array.from(new Set(data.map((item) => item.shipment_status))) as ShipmentStatus[]

  const statusMapUI = {
    Activa: t('Shipments.Table.Columns.Status.Active'),
    Entregada: t('Shipments.Table.Columns.Status.Delivered'),
    'En transito': t('Shipments.Table.Columns.Status.InTransit'),
    Excepcion: t('Shipments.Table.Columns.Status.Exception'),
    Solicitada: t('Cancellations.Table.Columns.Status.Solicited'),
    'Cancelada y usada': t('Cancellations.Table.Columns.Status.CancelledButUsed'), // C. y usada/C. but used 
    'Saldo abonado': t('Cancellations.Table.Columns.Status.Refunded'), // Abonada/Refunded
    'Cancelada de prov': t('Cancellations.Table.Columns.Status.CancelledFromProvider')
  }

  return [
    {
      title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Cancellations.Table.Columns.Date')}</Tooltip>,
      dataIndex: 'created_at',
      align: 'center',
      showSorterTooltip: false,
      sorter: (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (createdAt: string) => new Date(createdAt).toLocaleString()
    },
    {
      title: t('Cancellations.Table.Columns.User'),
      dataIndex: ['profile', 'email'],
      align: 'center'
    },
    {
      title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Cancellations.Table.Columns.Status')}</Tooltip>,
      dataIndex: 'shipment_status',
      align: 'center',
      showSorterTooltip: false,
      sorter: (a, b) => a.shipment_status.localeCompare(b.shipment_status),
      filters: uniqueShipmentStatus.map((status) => ({ text: statusMapUI[status], value: status })),
      onFilter: (value, record) => record.shipment_status.indexOf(value as string) === 0,
      render: (status) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <CancelStatus status={status} />
        </div>
      )
    },
    {
      title: t('Cancellations.Table.Columns.Tracking'),
      dataIndex: '',
      align: 'center',
      render: (rowData: any) => (
        <a href={linkRastreo(rowData?.carrier, rowData?.tracking)} target="_blank" rel="noopener noreferrer" style={{ color: 'black' }}>
          {rowData?.tracking}
        </a>
      )
    },
    {
        title: <Tooltip title={t('Table.Tooltip.Sort.Ascending')}>{t('Cancellations.Table.Columns.Total')}</Tooltip>,
        dataIndex: 'price',
        align: 'center',
        showSorterTooltip: false,
        sorter: (a, b) => a.price - b.price,
        render: (price: any) => `$ ${price}`
    },
    {
      title: t('Cancellations.Table.Columns.Carrier'),
      dataIndex: 'carrier',
      align: 'center',
      filters: uniqueCarriers.map((carrier) => ({ text: carrier, value: carrier })),
      onFilter: (value, record) => record.carrier.indexOf(value as string) === 0,
      render: (carrier) => getParcelImage(carrier)
    },
    {
      title: t('Cancellations.Table.Columns.Status'),
      dataIndex: '',
      align: 'center',
      render: (rowData: any) => (
        <UpdateStatusForm key={rowData.tracking} refetch={refetch} data={rowData} setData={setDataColumns} />
      ),
    },
    {
      title: t('Cancellations.Table.Columns.Actions'),
      dataIndex: '',
      align: 'center',
      render: (rowData: any) => (
        <CancelationsActions refetch={refetch} data={rowData} />
      ),
    },
  ]
}

const CancellationsDownloads = (data: any[])=> {
  return data.map(item => ({
      'Fecha de creacion': new Date(item?.created_at).toLocaleDateString(),
      Tracking: item.tracking,
      'Origen/Nombre': item.origin.name,
      'Origen/Compañia': item.origin.company,
      'Origen/Email': item.origin.email,
      'Origen/Telefono': item.origin.phone,
      'Origen/Calle': item.origin.street,
      'Origen/Numero de calle': item.origin.number,
      'Origen/Pais': item.origin.country,
      'Origen/Ciudad': item.origin.city,
      'Origen/Estado': item.origin.state,
      'Origen/Distrito': item.origin.district,
      'Origen/Codigo postal': item.origin.postal_code,
      'Origen/Referencia': item.origin.reference,
      'Destino/Nombre': item.destination.name,
      'Destino/Compañia': item.destination.company,
      'Destino/Email': item.destination.email,
      'Destino/Telefono': item.destination.phone,
      'Destino/Calle': item.destination.street,
      'Destino/Numero de calle': item.destination.number,
      'Destino/Pais': item.destination.country,
      'Destino/Ciudad': item.destination.city,
      'Destino/Estado': item.destination.state,
      'Destino/Distrito': item.destination.district,
      'Destino/Codigo postal': item.destination.postal_code,
      'Destino/Referencia': item.destination.reference,
      Paqueteria: item.carrier,
      Total: Number(item.price),
      Estado: item.shipment_status,
  }))
}

export default CancellationsV2